import { HttpErrorResponse } from '@angular/common/http';

abstract class GlobalError extends Error {
	messageKey?: string;
}

export class NoCurrentCommunityError extends GlobalError {
	constructor() {
		super('No current community.');
		this.messageKey = 'no-current-community';
	}
}
export class HttpError extends GlobalError {
	constructor(httpError: HttpErrorResponse) {
		super(`HTTP Error: ${JSON.stringify(httpError, null, 2)}`);
		this.messageKey = 'http-error';
	}
}

export class UserDoesNotExistError extends GlobalError {
	constructor() {
		super('User does not exist.');
		this.messageKey = 'user-does-not-exist';
	}
}
export class UserNotFoundError extends GlobalError {
	constructor() {
		super('User not found.');
		this.messageKey = 'user-not-found';
	}
}
