import { BehaviorSubject, Observable } from 'rxjs';
import { FirebaseUser } from './firebase-user';
import { User } from '../../../shared/model/user';
import { SessionState } from './session-state';
import { SessionData } from './session-data';
import { UserNotFoundError } from '../error/errors';

export class Session {
	private _firebaseUser?: FirebaseUser;
	private _user?: User;
	private _state!: SessionState;
	private _data$ = new BehaviorSubject<SessionData>({ state: SessionState.INITIAL });

	constructor() {
		this.updateBySessionState(SessionState.INITIAL);
	}

	select(): Observable<SessionData> {
		return this._data$;
	}

	destroy() {
		this.sessionData = null;
	}

	set(data: Partial<SessionData>): void {
		this.sessionData = data;
	}

	get(): SessionData {
		return this.sessionData;
	}

	updateByFirebaseUser(firebaseUser: FirebaseUser) {
		this.set({
			firebaseUser,
			user: undefined,
			state: SessionState.USER_PENDING
		});
	}

	updateByUser(user?: User) {
		if (!this._firebaseUser) {
			throw new UserNotFoundError();
		}
		this.set({
			firebaseUser: this._firebaseUser,
			user: new User(user)
		});
	}

	updateBySessionState(state: SessionState) {
		this.set({
			firebaseUser: this._firebaseUser,
			user: this._user,
			state
		});
	}

	private set sessionData(session: Partial<SessionData> | null) {
		if (session === null) {
			this._firebaseUser = undefined;
			this._user = undefined;
			this._state = SessionState.INITIAL;
		} else {
			this._firebaseUser = session.firebaseUser;
			this._user = session.user;
			this._state = session.state ?? this._user ? SessionState.VALID : SessionState.USER_MISSING;
		}
		this._data$.next({
			firebaseUser: this._firebaseUser,
			user: this._user,
			state: this._state
		});
	}

	private get sessionData(): SessionData {
		return this._data$.getValue();
	}
}
