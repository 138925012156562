<div *ngIf="userChoice">
	<!-- - Das Setting der View muss aus Config kommen -> https://github.com/saschajungfer/top-100-frontend/issues/2-->
	<div class="grid grid-cols-5 text-center text-xs p-6 text-slate-400">
		<div class="-rotate-45 whitespace-nowrap">100 – 81</div>
		<div class="-rotate-45 whitespace-nowrap">80 – 61</div>
		<div class="-rotate-45 whitespace-nowrap">60 – 41</div>
		<div class="-rotate-45 whitespace-nowrap">40 – 21</div>
		<div class="-rotate-45 whitespace-nowrap">20 – 1</div>
	</div>
	<div class="grid grid-cols-5 gap-2 px-3">
		<div *ngFor="let track of sortedTracks">
			<div class="aspect-square">
				<button *ngIf="!userChoice.hasStarted()" (click)="handleCellClick(track)" class="p-1 h-full w-full text-center flex flex-col items-center justify-center rounded-md bg-gray-100 hover:bg-gray-300 group transition-colors overflow-hidden">
					<div *ngIf="track.isPlaceholder()" class="text-gray-400 group-hover:text-gray-700">
						<mat-icon>add_circle</mat-icon>
					</div>

					<div *ngIf="!track.isPlaceholder()">
						<div class="md:hidden text-primary">
							<mat-icon>check_circle</mat-icon>
						</div>
						<div class="p-2 leading-normal hidden md:block">
							<div>{{ track.name }}</div>
							<div class="text-gray-500">–</div>
							<div class="text-sm italic">{{ track.artist }}</div>
						</div>
					</div>
				</button>

				<div
					*ngIf="userChoice.hasStarted()"
					[ngClass]="{
						'track-matches-result-list': track.matchesResult(),
						'track-matches-section': track.matchesSection()
					}"
					class="relative h-full w-full flex flex-col items-center justify-center rounded-md bg-gray-100 text-center overflow-hidden"
				>
					<div class="md:hidden">
						<mat-icon *ngIf="!track.matchesResult()" class="text-gray-400">cancel</mat-icon>
						<mat-icon *ngIf="track.matchesResult() && !track.matchesSection()">check_circle</mat-icon>
						<mat-icon *ngIf="track.matchesSection()">verified</mat-icon>
					</div>
					<div class="p-2 leading-normal hidden md:block whitespace-normal">
						<div>{{ track.name }}</div>
						<div class="text-gray-500">
							<span *ngIf="!track.isNumberOneGuess()">–</span>
							<div>
								<mat-icon *ngIf="track.isNumberOneGuess()">trophy</mat-icon>
							</div>
						</div>
						<div class="italic">{{ track.artist }}</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
