<div class="navbar items-center">
	<div class="navbar-start">
		<h2 *ngIf="title" class="font-bold text-xl">{{ title }}</h2>
	</div>

	<div class="navbar-end">
		<button aria-label="close dialog" class="btn btn-ghost btn-circle" (click)="onClose()">
			<mat-icon>close</mat-icon>
		</button>
	</div>
</div>
