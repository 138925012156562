import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Round } from '../../../../../shared/model/round';
import { ActivatedRoute } from '@angular/router';
import { finalize, first, map } from 'rxjs/operators';
import { User } from '../../../../../shared/model/user';
import { Observable, tap } from 'rxjs';
import { RoundService } from '../../../services/round.service';
import { UserChoiceService } from '../../../services/user-choice.service';
import { SessionService } from '../../../services/session.service';
import { UserChoice } from '../../../../../shared/model/user-choice';
import { OverlayService } from '../../../services/overlay.service';
import { Track } from '../../../../../shared/model/track';
import { RoundResult } from '../../../../../shared/model/round-result';

@Component({
	selector: 'app-round-user-choices-view',
	templateUrl: './round-user-choices-view.component.html',
	styleUrls: ['./round-user-choices-view.component.css']
})
export class RoundUserChoicesViewComponent implements OnInit {
	round: Round;
	currentUser: User;
	choices: UserChoice[];
	selectedChoice: UserChoice;
	reclaimPointsForTrack: Track;
	mode: string = 'choices';

	constructor(private activatedRoute: ActivatedRoute, private sessionService: SessionService, private roundService: RoundService, private userChoiceService: UserChoiceService, private overlayService: OverlayService) {}

	@ViewChild('detailDialog') detailDialog!: TemplateRef<any>;
	@ViewChild('reclaimTrack') reclaimTrackDialog!: TemplateRef<any>;

	ngOnInit() {
		this.executeFunctionAndShowLoading(() => this.loadData()).subscribe();
	}

	/**
	 * Ggf. diese kann diese Methode verschoben werden nach OverlayService
	 * @param func
	 */
	executeFunctionAndShowLoading<T>(func: () => Observable<T>): Observable<T> {
		this.overlayService.showLoadingOverlay();
		return func().pipe(finalize(() => this.overlayService.closeLoadingOverlay()));
	}

	/**
	 * FIXME: Nochmal sauber implementieren. Wennsich die Round ändert, werden dann auch die choicces neu gegladen? Prüfen!
	 * @protected
	 */
	protected loadData(): Observable<any> {
		this.currentUser = this.sessionService.getUser();
		return this.getRoundByRouteParam().pipe(
			first(),
			tap((roundId: string) => {
				this.roundService.getRound(roundId).subscribe((round: Round) => {
					this.round = round;
					this.userChoiceService.getUserChoicesByRound({ round }).subscribe((choices: UserChoice[]) => {
						this.choices = choices
							.filter((choice: UserChoice) => choice.tracks?.length > 0)
							.sort((a: UserChoice, b: UserChoice) => {
								if (a.getScore() === b.getScore()) {
									return a.user.username.localeCompare(b.user.username);
								}
								return a.getScore() > b.getScore() ? -1 : 1;
							});
						this.selectedChoice = this.selectedChoice ?? choices.find((choice) => choice.user.id === this.currentUser.id) ?? choices[0];
					});
				});
			})
		);
	}

	private getRoundByRouteParam(): Observable<string> {
		return this.activatedRoute.paramMap.pipe(map((params) => params.get('id')));
	}

	showChoice(id: string): void {
		this.selectedChoice = this.choices.find((choice) => choice.id === id);
		this.overlayService.open(this.detailDialog);
	}

	onChoiceSelected(id: string): void {
		this.selectedChoice = this.choices.find((choice) => choice.id === id);
	}

	switchMode(mode: string): void {
		this.mode = mode;
	}

	onReclaimPointsForTrack(track: Track): void {
		this.reclaimPointsForTrack = track;
		this.overlayService.open(this.reclaimTrackDialog);
	}

	async onReclaimPointsForTrackSelect(roundResult: RoundResult): Promise<void> {
		const message = `Bist du sicher, dass <b>${this.reclaimPointsForTrack.name}</b> – <i>${this.reclaimPointsForTrack.artist}</i> mit <b>${roundResult.track}</b> – <i>${roundResult.artist}</i> übereinstimmt?`;
		this.overlayService.confirm({ message }, async () => {
			this.overlayService.closeAll();
			await this.userChoiceService.updateUserChoiceReclaimPointsForTrack({ choice: this.selectedChoice, track: this.reclaimPointsForTrack, result: roundResult });
			this.overlayService.snack({ message: 'Super, deine Anfrage wird in kürze bearbeitet.' });
		});
	}
}
