import { Section } from './section';
import { LodashUtil } from '../util/lodash.util';
import { classToObject } from '../util/firebase.util';
import { RoundResult } from './round-result';
import { Round } from './round';

export class Track {
	id: string;
	name: string;
	artist: string;

	sectionId: string;
	matchingTrackId: string;
	scoreTags: string[];
	sectionPosition: number;
	count: number;
	spotify?: SpotifyTrackMetaData;

	constructor(vo: any) {
		this.id = vo.id;
		this.artist = vo.artist;
		this.name = vo.name;
		this.count = vo.count;
		this.sectionId = vo.sectionId;
		this.matchingTrackId = vo.matchingTrackId;
		this.scoreTags = vo.scoreTags;
		this.sectionPosition = vo.sectionPosition;
		this.spotify = vo.spotify;
	}

	isOutOfScoringRange(): boolean {
		return !this.isInScoringRange();
	}

	isInScoringRange(): boolean {
		return ['1', '2', '3', '4', '5'].includes(this.sectionId) && this.sectionPosition <= 5;
	}

	getScore(): number {
		return this.count ?? 0;
	}

	/**
	 * FIXME(Score): section match needs better implementation
	 * MATCHES_RESULT and MATCHES_SECTION als ENUM auslagern
	 * Tests
	 * @param result
	 */
	setScore(result: RoundResult): void {
		let count = 0;
		if (result) {
			if (!this.isOutOfScoringRange()) {
				this.matchingTrackId = result.id;
				this.setScoreTag('MATCHES_RESULT');
				count += 1;

				const section: string = result.position > 80 ? '5' : result.position > 60 ? '4' : result.position > 40 ? '3' : result.position > 20 ? '2' : '1';
				if (section === this.sectionId) {
					count += 1;
					this.setScoreTag('MATCHES_SECTION');
				}
				if (result.position === 1 && this.isNumberOneGuess()) {
					count += 10;
					this.setScoreTag('MATCHES_NUMBER_ONE');
				}
			}
		}
		if (count > 0) {
			this.count = count;
		}
	}

	private setScoreTag(tag: string): void {
		if (!this.scoreTags) {
			this.scoreTags = [];
		}
		if (!this.scoreTags.includes(tag)) {
			this.scoreTags.push(tag);
		}
	}

	matchesNumberOne(): boolean {
		return this.scoreTags?.includes('MATCHES_NUMBER_ONE');
	}

	matchesResult(): boolean {
		return this.scoreTags?.includes('MATCHES_RESULT');
	}

	matchesSection(): boolean {
		return this.scoreTags?.includes('MATCHES_SECTION');
	}

	isPlaceholder(): boolean {
		return this.artist === '*' && this.name === '*';
	}

	isNumberOneGuess(): boolean {
		return this.sectionId === '1' && this.sectionPosition === 1;
	}

	reorder({ sectionId, sectionPosition }: { sectionId: string; sectionPosition: number }): void {
		this.sectionId = sectionId ?? this.sectionId;
		this.sectionPosition = sectionPosition ?? this.sectionPosition;
	}

	public static createPlaceholderTrack({ sectionId, sectionPosition }: { sectionId: string; sectionPosition: number }): Track {
		return new Track({
			artist: '*',
			name: '*',
			sectionId,
			sectionPosition
		});
	}

	public validate(sections: Section[], tracks: Track[]): void {
		if (LodashUtil.isEmpty(this.artist)) {
			throw new Error('Artist is mandatory');
		}
		if (LodashUtil.isEmpty(this.name)) {
			throw new Error('Track is mandatory');
		}
		if (LodashUtil.isEmpty(this.sectionId)) {
			throw new Error('Section is mandatory');
		}
		sections.forEach((section: Section) => {
			const findTrackByArtistAndTrack = tracks?.find((item: Track) => item.artist === this.artist && item.name === this.name);
			if (findTrackByArtistAndTrack) {
				throw new Error(`Track already exists in section ${section.name}`);
			}
		});
	}

	toObject(): TrackVO {
		return classToObject(this);
	}

	getMatchingTrackPosition(round: Round): number {
		if (this.matchingTrackId) {
			const result = round.results.find((result: RoundResult) => result.id === this.matchingTrackId);
			if (result) {
				return result.position;
			}
		}
		return undefined;
	}

	getAbsolutPosition(): number {
		return this.sectionPosition + (Number(this.sectionId) - 1) * 5;
	}
}
class SpotifyTrackMetaData {
	id: string;
	uri: string;
}
export class PlainTrack {
	artist: string;
	name: string;
	exists?: boolean;
	spotify: SpotifyTrackMetaData;
}

export interface TrackVO extends Partial<Track> {}
