import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Track } from '../../../../../shared/model/track';
import { UserChoice } from '../../../../../shared/model/user-choice';

@Component({
	selector: 'app-user-choice',
	templateUrl: './user-choice-view.component.html',
	styleUrls: ['./user-choice-view.component.css']
})
export class UserChoiceViewComponent implements OnInit, OnChanges {
	@Input() userChoice: UserChoice;
	@Output() cellClick = new EventEmitter<Track>();

	sortedTracks: Track[] = [];

	constructor() {}

	ngOnInit() {
		this.sortedTracks = this.userChoice.getSortedTracks();
	}

	ngOnChanges(changes: SimpleChanges) {
		// @ts-ignore
		if (changes.userChoice) {
			this.sortedTracks = this.userChoice.getSortedTracks();
		}
	}

	handleCellClick(track: Track) {
		this.cellClick.emit(track);
	}
}
