import { Component, Input, OnInit } from '@angular/core';
import { RoundResult } from '../../../../../shared/model/round-result';
import { Round } from '../../../../../shared/model/round';

@Component({
	selector: 'app-round-result-view',
	templateUrl: './round-result-view.component.html',
	styleUrls: ['./round-result-view.component.css']
})
export class RoundResultView implements OnInit {
	@Input()
	round: Round;
	results: RoundResult[] = [];

	constructor() {}

	async ngOnInit() {
		this.results = this.round?.results?.sort((a, b) => a.position - b.position);
	}
}
