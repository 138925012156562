<app-dialog-header title="Song hinzufügen"></app-dialog-header>
<div class="dialog-content">
	<div *ngIf="mode === 'search'">
		<label class="input input-bordered flex items-center gap-2">
			<input cdkFocusInitial [(ngModel)]="search" (ngModelChange)="onSearchChange($event)" placeholder="Artist oder Song" class="grow" />
			<div *ngIf="isLoading">
				<span class="loading loading-spinner loading-xs"></span>
			</div>
		</label>

		<div class="flex flex-col divide-y">
			<button class="w-full text-left p-3 hover:bg-gray-200" *ngFor="let track of spotifySearchResults; let last = last" (click)="onSelect(track)">
				<span class="italic">{{ track.name }}</span> <span class="text-gray-500"> – </span> <span class="text-sm italic">{{ track.artist }}</span>
			</button>
		</div>
	</div>

	<div *ngIf="mode === 'edit'">
		<form #addTrackForm="ngForm" (ngSubmit)="submitTrack(addTrackForm)" class="flex flex-col gap-3">
			<div class="form-control">
				<input #artistControl="ngModel" name="artist" required [(ngModel)]="artist" placeholder="Artist" class="input input-bordered" />
				<div class="form-field-error-message" *ngIf="artistControl.touched && artistControl.invalid">Artist ist ein Pflichtfeld</div>
			</div>
			<div class="form-control">
				<input required #trackControl="ngModel" name="name" [(ngModel)]="name" placeholder="Song" class="input input-bordered" />
				<div class="form-field-error-message" *ngIf="trackControl.touched && trackControl.invalid">Song ist ein Pflichtfeld</div>
			</div>
			<app-section-selector [(ngModel)]="sectionId" name="sectionId" [userChoice]="userChoice"></app-section-selector>

			<button type="submit" class="btn">Song übernehmen</button>
		</form>
	</div>
</div>

<div class="dialog-actions">
	<button (click)="switchMode()" class="btn btn-ghost m-auto">
		<span *ngIf="mode === 'search'"> Song nicht gefunden? </span>

		<span *ngIf="mode === 'edit'"> Zur Suche </span>
	</button>
</div>
