<div class="px-safe">
	<div class="hero">
		<div class="hero-content w-full">
			<div class="w-full">
				<div class="text-4xl max-w-96 m-auto text-center p-6">💯</div>
				<form ngNativeValidate (keydown.enter)="onSubmit(form)" (ngSubmit)="onSubmit(form)" #form="ngForm" class="flex flex-col gap-3 max-w-96 m-auto">
					<label class="form-control">
						<input [(ngModel)]="email" #emailControl="ngModel" name="email" [type]="'email'" required placeholder="E-Mail" class="input input-bordered text-center" />
						<!-- <div *ngIf="emailControl.hasError('required')"
								class="label">E-Mail ist erfolderlich!</div>
							<div *ngIf="emailControl.hasError('email')"
								class="label">E-Mail hat ein unfültiges Format!</div> -->
					</label>
					<label class="form-control">
						<input [(ngModel)]="password" #passwordControl="ngModel" name="password" [type]="'password'" required placeholder="Kennwort" class="input input-bordered w-full text-center" />
						<!-- <div
								*ngIf="emailControl.hasError('required')"
								class="label"
							>
								Passwort ist erfolderlich!
							</div> -->
					</label>

					<button type="submit" class="btn w-full">Anmelden</button>

					<a class="btn btn-ghost w-full" routerLink="/register">Registrieren</a>

					<button class="btn btn-ghost w-full" data-test="reset-password-button" (click)="onResetPassword()">Kennwort vergessen</button>
				</form>
			</div>
		</div>
	</div>
</div>

<ng-template #resetDialog>
	<div class="hero">
		<div class="hero-content">
			<form ngNativeValidate (keydown.enter)="onSubmitReset()" (ngSubmit)="onSubmitReset()" class="flex flex-col gap-3 max-w-96 m-auto">
				<label class="form-control">
					<input [type]="email" required email [(ngModel)]="emailReset" name="emailReset" placeholder="E-Mail-Adresse" data-test="passwort-reset-email-input" class="input input-bordered w-full text-center" />
				</label>
				<button type="submit" data-test="password-reset-submit-button" class="btn w-full">Kennwort zurücksetzen</button>
			</form>
		</div>
	</div>
</ng-template>
