import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import firebase from 'firebase/compat';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { FirebaseUser } from '../model/firebase-user';
import { SignupVo } from '../../../shared/model/signup.vo';

@Injectable({
	providedIn: 'root'
})
export class AuthService {
	constructor(public afAuth: AngularFireAuth) {}

	selectAuthState(): Observable<FirebaseUser | null> {
		return this.afAuth.authState;
	}

	async signup({ email, password, username }: SignupVo): Promise<firebase.User> {
		const userCredential: firebase.auth.UserCredential = await this.afAuth.createUserWithEmailAndPassword(email, password);
		await userCredential.user.updateProfile({ displayName: username });

		return userCredential.user;
	}

	async signInWithEmailAndPassword(email: string, password: string): Promise<FirebaseUser> {
		return this.afAuth
			.signInWithEmailAndPassword(email, password)
			.then((credential: firebase.auth.UserCredential) => {
				return credential.user;
			})
			.catch((error) => {
				throw error;
			});
	}

	async logout() {
		await this.afAuth.signOut();
	}

	async sendPasswordResetEmail(email: string): Promise<void> {
		return this.afAuth.sendPasswordResetEmail(email);
	}
}
