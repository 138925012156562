<!-- FIXME(spotify): Needs better styling -->
<ng-container *ngIf="accessToken">
	<span>Die Verbindung mit deinem Spotify-Account wurde erfolgreich hergestellt.</span>
</ng-container>
<ng-container *ngIf="!accessToken">
	<span>Fehler beim Verbinden, bitte probiere es noch einmal.</span>
</ng-container>
<div class="content-center">
	<button class="btn" (click)="navigateToDashboard()">Zurück zum Dashboard</button>
</div>
