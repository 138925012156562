import { CanActivate, Router, UrlTree } from '@angular/router';
import { Injectable } from '@angular/core';
import { SessionService } from '../services/session.service';
import { Observable, switchMap, take } from 'rxjs';
import { User } from '../../../shared/model/user';

@Injectable({
	providedIn: 'root'
})
export class HasCurrentCommunityGuard implements CanActivate {
	constructor(private router: Router, private sessionService: SessionService) {}

	canActivate(): Observable<boolean | UrlTree> {
		return this.sessionService.selectUserAndFilterNilValue().pipe(
			take(1),
			switchMap(async (user: User) => {
				if (user.currentCommunity) {
					return true;
				} else if (user.memberships?.length === 1) {
					await this.sessionService.loadAndActivateCommunity(user.memberships[0].community);
					return true;
				} else {
					return this.router.parseUrl('/communities');
				}
			})
		);
	}
}
