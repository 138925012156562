export const generateFirebaseUID = (): string => {
	let uid = '';
	const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
	const charactersLength = characters.length;
	for (let i = 0; i < 20; i++) {
		uid += characters.charAt(Math.floor(Math.random() * charactersLength));
	}
	return uid;
};

export const classToObject = (instance: any): any => {
	const object: any = { ...instance };
	// delete all undefined fields
	Object.keys(object).forEach((key) => object[key] === undefined && delete object[key]);

	return object;
};
