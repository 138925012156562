import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { LoadingOverlayComponent } from './loading-overlay/loading-overlay.component';
import { UiLongPressDirective } from './long-press-directive/ui-long-press.directive';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { CommonModule } from '@angular/common';
import { ErrorComponent } from './error/error.component';
import { DialogHeaderComponent } from './dialog-header/dialog-header.component';

@NgModule({
	imports: [
		// Angular
		CommonModule,
		MatIconModule
	],
	declarations: [ConfirmationDialogComponent, DialogHeaderComponent, ErrorComponent, LoadingOverlayComponent, UiLongPressDirective],
	exports: [
		// Components
		ConfirmationDialogComponent,
		ErrorComponent,
		LoadingOverlayComponent,
		UiLongPressDirective,
		CommonModule,
		MatIconModule,
		DialogHeaderComponent
	]
})
export class UiModule {}
