import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';
import { Round } from '../../../shared/model/round';
import { map } from 'rxjs/operators';
import { FirestoreReference } from '../../../shared/model/firestore-reference';
import { classToObject } from '../../../shared/util/firebase.util';

@Injectable({
	providedIn: 'root'
})
export class RoundService {
	constructor(private firestore: AngularFirestore) {}

	getRound(id: string): Observable<Round | null> {
		const document = this.firestore.collection('rounds').doc(id);
		return document.snapshotChanges().pipe(
			map((snapshot: any) => {
				if (snapshot.payload?.exists) {
					const roundData = snapshot.payload.data();
					return new Round({ ...roundData, id: snapshot.payload.id });
				} else {
					return null;
				}
			})
		);
	}

	getRoundsByCommunity({ communityRefPath }: { communityRefPath: FirestoreReference }): Observable<Round[]> {
		const communityRef = this.firestore.doc(communityRefPath);
		const collection = this.firestore.collection(`rounds`, (ref) => ref.where('communityRef', '==', communityRef.ref));
		return collection.snapshotChanges().pipe(
			map((changes: any) =>
				changes.map((c: any) => {
					return new Round({ id: c.payload.doc.id, ...c.payload.doc.data() });
				})
			)
		);
	}

	/**
	 * FIXME: Das  muss zwingend abgesichert werden. Ggf. überlegen, dass auf den Server zu verschieben.
	 * @param round
	 */
	async updateRound(round: Round) {
		const id = round.id;
		const vo = classToObject(round);
		return this.firestore.doc(`rounds/${id}`).update(vo);
	}
}
