import { SpotifyTrack } from './spotify-track';
import { PlainTrack, Track } from '../../../track';

export class SpotifySearchTrackResult {
	tracks!: {
		items: SpotifyTrack[];
	};

	public static convertToTrackListAndRemoveDuplicates({ spotifySearchTrackResult, currentUserTracks }: { spotifySearchTrackResult: SpotifySearchTrackResult; currentUserTracks: Track[] }): PlainTrack[] {
		const trackList: PlainTrack[] = this.convertToTrackList({ spotifySearchTrackResult, currentUserTracks });
		return this.removeDuplicates(trackList);
	}

	public static createEmpty(): SpotifySearchTrackResult {
		return { tracks: { items: [] } };
	}

	private static convertToTrackList({ spotifySearchTrackResult, currentUserTracks }: { spotifySearchTrackResult: SpotifySearchTrackResult; currentUserTracks: Track[] }): PlainTrack[] {
		const trackList: PlainTrack[] = spotifySearchTrackResult?.tracks?.items?.map((item: SpotifyTrack) => {
			const name = item.name;
			const artist = item.artists.map((artist) => artist.name).join(', ');
			const exists = currentUserTracks.some((track: Track) => track.artist === artist && track.name === name);
			const spotify = item.uri?.length > 0 ? { id: item.id, uri: item.uri } : undefined;
			return { name, artist, exists, spotify };
		});
		return this.removeDuplicates(trackList);
	}

	private static removeDuplicates(tracks: PlainTrack[]): PlainTrack[] {
		return tracks.filter((track, index, self) => self.findIndex((t) => t.artist === track.artist && t.name === track.name) === index);
	}
}
