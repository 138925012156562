import { Injectable } from '@angular/core';
import { Dialog, DialogConfig, DialogRef } from '@angular/cdk/dialog';
import { LoadingOverlayComponent } from '../modules/ui/loading-overlay/loading-overlay.component';
import { ConfirmationDialogComponent } from '../modules/ui/confirmation-dialog/confirmation-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ErrorComponent } from '../modules/ui/error/error.component';
import { Overlay } from '@angular/cdk/overlay';

@Injectable({
	providedIn: 'root'
})
export class OverlayService {
	private _loadingDialog?: DialogRef<any, LoadingOverlayComponent>;

	constructor(private cdkDialog: Dialog, private snackBar: MatSnackBar, private overlay: Overlay) {}

	open(component: any, data?: DialogConfig): DialogRef<any> {
		data = data || {};
		data.panelClass = data.panelClass ?? 'custom-dialog-container';
		return this.cdkDialog.open(component, data as any);
	}

	openOnTopWithNoBackdrop(component: any, data?: DialogConfig): DialogRef<any> {
		data = data || {};
		data.hasBackdrop = false;
		data.positionStrategy = this.overlay.position().global().top().centerHorizontally();
		return this.open(component, data as any);
	}

	closeAll() {
		this.cdkDialog.closeAll();
	}
	showLoadingOverlay() {
		this._loadingDialog = this.cdkDialog.open(LoadingOverlayComponent, {
			disableClose: true
		});
	}
	closeLoadingOverlay() {
		this._loadingDialog?.close();
	}

	confirm(data: any, callback?: () => void) {
		const dialogRef = this.open(ConfirmationDialogComponent, { data });
		dialogRef.closed.subscribe(async (confirmed: boolean) => {
			if (confirmed) {
				if (callback) {
					callback();
				}
			}
		});
	}

	snack({ message, action = 'OK', duration = 2000 }: { message: string; action?: string; duration?: number }) {
		this.snackBar.open(message, action, {
			duration
		});
	}

	error({ message }: { message: string }) {
		this.cdkDialog.open(ErrorComponent, {
			data: { message },
			height: '50vh',
			panelClass: 'error-dialog'
		});
	}
}
