<header class="px-safe bg-gray-800 text-white sticky top-0 z-50" *ngIf="user$ | async as user">
	<div class="navbar gap-3">
		<div class="flex-none">
			<button class="btn btn-circle btn-ghost hover:bg-gray-700" (click)="onMenuClick()">
				<mat-icon>menu</mat-icon>
			</button>
		</div>
		<div class="flex-grow">
			<a routerLink="/" class="btn btn-ghost hover:bg-gray-700">{{ user.currentCommunity?.name }}</a>
		</div>
		<!-- TODO Wird erst wieder aktiviert, wenn Anleitung fertig 
		<div>
			<button class="btn btn-circle btn-ghost hover:bg-gray-700" (click)="onQuestion()">
				<mat-icon>lightbulb_outline</mat-icon>
			</button>
		</div>-->
	</div>
</header>

<ng-template #menu>
	<app-dialog-header></app-dialog-header>
	<div class="dialog-content">
		<ul class="menu gap-2">
			<li>
				<button routerLink="/" (click)="onMenuItemClick()">
					<mat-icon>dashboard</mat-icon>
					<span>Dashboard</span>
				</button>
			</li>
			<li>
				<button routerLink="/communities" (click)="onMenuItemClick()">
					<mat-icon>group</mat-icon>
					<span>Communities</span>
				</button>
			</li>
			<!-- 
			<li>
				<button mat-menu-item routerLink="/settings">
					<mat-icon>settings</mat-icon>
					<span>Settings</span>
				</button>
			</li>
 -->
			<li>
				<button routerLink="/highscore" (click)="onMenuItemClick()">
					<mat-icon>social_leaderboard</mat-icon>
					<span>Highscore</span>
				</button>
			</li>
			<ng-container *ngIf="user$ | async as user">
				<li *ngIf="user.canUseSpotify()">
					<button routerLink="/spotify-account" (click)="onMenuItemClick()">
						<mat-icon>music_note</mat-icon>
						<span>Spotify Account</span>
					</button>
				</li>
				<li *ngIf="user.isAdmin()">
					<button routerLink="/reclaims" (click)="onMenuItemClick()">
						<mat-icon>support_agent</mat-icon>
						<span>Beschwerdemanagement</span>
					</button>
				</li>
				<li>
					<button class="text-error" (click)="onLogout()">
						<mat-icon color="warn">logout</mat-icon>
						<span color="warn">Logout</span>
					</button>
				</li>
			</ng-container>
		</ul>
	</div>
</ng-template>

<main class="grow flex flex-col">
	<router-outlet></router-outlet>
</main>
