import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UserChoice } from '../../../../../shared/model/user-choice';
import { Track } from '../../../../../shared/model/track';

@Component({
	selector: 'app-user-choice-edit-grid',
	templateUrl: './user-choice-edit-grid.component.html',
	styleUrls: ['./user-choice-edit-grid.component.css']
})
export class UserChoiceEditGridComponent {
	@Input()
	userChoice: UserChoice;
	@Output() trackSelected = new EventEmitter<Track>();
	@Output() trackAdded = new EventEmitter<string>();

	constructor() {}

	handleCellClick(track: Track) {
		if (track.id) {
			this.trackSelected.emit(track);
		} else {
			this.trackAdded.emit(track.sectionId);
		}
	}
}
