<div class="px-safe">
	<div class="hero">
		<div class="hero-content w-full">
			<div class="w-full">
				<div class="text-4xl max-w-96 m-auto text-center p-6">💯</div>
				<div class="text-2xl max-w-96 m-auto text-center p-6">Neuer Account</div>
				<div>
					<form (keydown.enter)="onSubmit(form)" (ngSubmit)="onSubmit(form)" #form="ngForm" class="flex flex-col gap-3 max-w-96 m-auto">
						<label class="form-control">
							<input required [type]="'text'" [(ngModel)]="username" #userControl="ngModel" name="username" placeholder="Benutzername" class="input input-bordered text-center" />
							<!-- <div class="label" *ngIf="userControl.hasError('required')">Username is required</div> -->
						</label>
						<label class="form-control">
							<input required email [type]="'email'" [(ngModel)]="email" #emailControl="ngModel" name="email" placeholder="E-Mail" class="input input-bordered text-center" />
							<!-- <mat-error *ngIf="emailControl.hasError('required')">Email is required</mat-error>
							<mat-error *ngIf="emailControl.hasError('email')">Email has an invalid format</mat-error> -->
						</label>
						<label class="form-control">
							<input required [type]="'password'" [(ngModel)]="password" #passwordControl="ngModel" name="password" placeholder="Kennwort" class="input input-bordered text-center" />
							<!-- <mat-error *ngIf="passwordControl.hasError('required')">Password is required</mat-error> -->
						</label>

						<button type="submit" class="btn">Registrieren</button>

						<a class="btn btn-ghost w-full" routerLink="/login">Zurück zum Login</a>
					</form>
				</div>
			</div>
		</div>
	</div>
</div>
