import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { AuthGuard } from '../../guards/auth.guard';
import { HighscoreComponent } from './highscore/highscore.component';
import { HasCurrentCommunityGuard } from '../../guards/has-current-community.guard';
import { RoundUserChoicesViewComponent } from './round-user-choices-view/round-user-choices-view.component';
import { UserChoiceEditComponent } from './user-choice-edit/user-choice-edit.component';

const routes: Routes = [
	{
		path: 'user-choice-edit/:id',
		component: UserChoiceEditComponent,
		canActivate: [AuthGuard, HasCurrentCommunityGuard]
	},
	{
		path: 'round-result-view/:id',
		component: RoundUserChoicesViewComponent,
		canActivate: [AuthGuard, HasCurrentCommunityGuard]
	},
	{
		path: 'highscore',
		component: HighscoreComponent,
		canActivate: [AuthGuard, HasCurrentCommunityGuard]
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class ChoiceRoutingModule {}
