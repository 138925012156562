import { APP_INITIALIZER, ErrorHandler, isDevMode, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { environment } from '../environments/environment';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { FIREBASE_OPTIONS } from '@angular/fire/compat';
import { ServiceWorkerModule } from '@angular/service-worker';
import { LoginModule } from './modules/login/login.module';
import { DashboardModule } from './modules/dashboard/dashboard.module';
import { SettingsModule } from './modules/settings/settings.module';
import { ChoiceModule } from './modules/choice/choice.module';
import { UiModule } from './modules/ui/ui.module';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { USE_EMULATOR as USE_AUTH_EMULATOR } from '@angular/fire/compat/auth';
import { SETTINGS as FIRESTORE_SETTINGS, USE_EMULATOR as USE_FIRESTORE_EMULATOR } from '@angular/fire/compat/firestore';
import { USE_EMULATOR as USE_FUNCTIONS_EMULATOR } from '@angular/fire/compat/functions';

import { Router } from '@angular/router';
import * as Sentry from '@sentry/angular';

const useEmulators = environment.useEmulators;
@NgModule({
	declarations: [AppComponent],
	imports: [
		AppRoutingModule,
		BrowserModule,
		BrowserAnimationsModule,
		ChoiceModule,
		DashboardModule,
		LoginModule,
		SettingsModule,
		UiModule,
		// Firebase
		provideFirebaseApp(() => initializeApp(environment.firebase)),
		provideAuth(() => getAuth()),
		provideFirestore(() => getFirestore()),

		// Service Worker
		ServiceWorkerModule.register('ngsw-worker.js', {
			enabled: !isDevMode(),
			// Register the ServiceWorker as soon as the application is stable
			// or after 30 seconds (whichever comes first).
			registrationStrategy: 'registerWhenStable:30000'
		})
	],
	providers: [
		// Firebase
		{ provide: FIREBASE_OPTIONS, useValue: environment.firebase },
		{ provide: USE_AUTH_EMULATOR, useValue: useEmulators ? ['http://localhost:9099'] : undefined },
		{ provide: USE_FIRESTORE_EMULATOR, useValue: useEmulators ? ['localhost', 8099] : undefined },
		{ provide: USE_FUNCTIONS_EMULATOR, useValue: useEmulators ? ['localhost', 5001] : undefined },
		{ provide: FIRESTORE_SETTINGS, useValue: { ignoreUndefinedProperties: true } },

		// Sentry
		// TODO: When enabled, this shows a sentry error dialog. This looks good in general, but can it be replaced with an own dialog?
		{ provide: ErrorHandler, useValue: Sentry.createErrorHandler({ showDialog: false }) },
		{ provide: Sentry.TraceService, deps: [Router] },
		{ provide: APP_INITIALIZER, useFactory: () => () => {}, deps: [Sentry.TraceService], multi: true }
	],
	bootstrap: [AppComponent]
})
export class AppModule {}
