import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-introduction',
	templateUrl: './introduction.component.html',
	styleUrls: ['./introduction.component.css']
})
export class IntroductionComponent {
  	@Input()
	currentStep = 1;
	maxSteps = 2;
	nextStep() {
		if (this.currentStep < this.maxSteps) {
			this.currentStep++;
		}
	}

	previousStep() {
		if (this.currentStep > 1) {
			this.currentStep--;
		}
	}
}
