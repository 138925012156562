import { AxiosRequestConfig } from 'axios';
import { Buffer } from 'buffer';
import { LodashUtil } from '../../../../shared/util/lodash.util';
import { SpotifySearchTrackResult } from '../../../../shared/model/external/spotify/dto/spotify-search-track-result';
import { TrackVO } from '../../../../shared/model/track';
import { AccessTokenResponseType } from '../../../../shared/model/external/spotify/dto/access-token-response.type';
import { get, post } from '../http.service';

export const searchTrack = async ({ searchQuery }: { searchQuery: string }): Promise<SpotifySearchTrackResult> => {
	if (LodashUtil.isEmpty(searchQuery)) {
		return SpotifySearchTrackResult.createEmpty();
	}
	const url = `https://api.spotify.com/v1/search?type=track&q=${encodeURIComponent(searchQuery)}`;
	const accessToken = await getAccessToken();
	const config: AxiosRequestConfig = {
		headers: {
			Authorization: `Bearer ${accessToken.access_token}`
		}
	};

	return await get({ url, config });
};

export const getTracksFromPlaylist = async (playlistUrl: string): Promise<TrackVO[]> => {
	const playlistId = extractPlaylistId(playlistUrl);

	// Retrieve all tracks from the playlist
	const result = await getPlaylistTracks(playlistId);
	return SpotifySearchTrackResult.convertToTrackListAndRemoveDuplicates(result);
};

const extractPlaylistId = (playlistUrl: string) => {
	// Extract the playlist ID from the URL
	const regex = /playlist\/([a-zA-Z0-9]+)/;
	const match = playlistUrl.match(regex);
	if (match && match[1]) {
		return match[1];
	}
	throw new Error('Invalid playlist URL');
};

const getPlaylistTracks = async (playlistId: string) => {
	const url = `https://api.spotify.com/v1/playlists/${playlistId}/tracks`;
	const accessToken = await getAccessToken();
	const config: AxiosRequestConfig = {
		headers: {
			Authorization: `Bearer ${accessToken.access_token}`
		}
	};

	return await get({ url, config });
};

const getAccessToken = async (): Promise<AccessTokenResponseType> => {
	const clientIdAndSecret = getClientIdAndSecret();
	const config = {
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
			Authorization: `Basic ${Buffer.from(`${clientIdAndSecret.clientId}:${clientIdAndSecret.clientSecret}`).toString('base64')}`
		}
	};

	const url = 'https://accounts.spotify.com/api/token';
	const data = 'grant_type=client_credentials';
	return post({ url, data, config });
};

const getClientIdAndSecret = (): { clientId: string; clientSecret: string } => {
	const clientId = '944b590644414b06928f515ba345e14e';
	const clientSecret = '94c83fdb29264553830f2e0929a90214';
	return {
		clientId,
		clientSecret
	};
};
