import { FirestoreReference } from './firestore-reference';
import { Track, TrackVO } from './track';
import { User } from './user';
import { Round } from './round';
import { generateFirebaseUID } from '../util/firebase.util';
import { verifyIfArtistAndTrackMatches } from '../../functions/src/util/match.util';

export class FirestoreUserChoice {
	round: Round;
	roundRef: FirestoreReference;

	user: User;
	userRef: FirestoreReference;

	tracks: Track[];
	score: number;
	scoreTags: string[];
	place: number;

	constructor(data: any) {
		Object.assign(this, data);
		this.user = new User(data.user);
	}
}

class Section {
	id: string;
	name: string;
	outOfScoreRange?: boolean;
}

export class UserChoice extends FirestoreUserChoice {
	id: string;
	// TODO(Track): Als Config auslagern:  https://github.com/saschajungfer/top-100-frontend/issues/2
	sectionsList: Section[] = [
		{ id: '1', name: '01 - 20' },
		{ id: '2', name: '21 - 40' },
		{ id: '3', name: '41 - 60' },
		{ id: '4', name: '61 - 80' },
		{ id: '5', name: '81 - 100' },
		{ id: '6', name: 'Pending Picks', outOfScoreRange: true }
	];

	constructor(data: any) {
		super(data);
		this.id = data.id;
		this.tracks = [];
		this.score = data.score ?? 0;
		this.scoreTags = data.scoreTags;
		data?.tracks?.forEach((track: any) => {
			this.tracks.push(new Track(track));
		});
		this.round = new Round(data.round);
	}

	addTrack(vo: TrackVO) {
		const sectionLength: number = this.getSectionLength(vo.sectionId) || 0;
		const track: Track = new Track({ ...vo, sectionPosition: sectionLength + 1, id: generateFirebaseUID() } as Partial<Track>);
		track.validate(this.sectionsList, this.tracks);
		this.tracks.push(track);
	}

	updatePosition({ sectionId, tracks }: { sectionId: string; tracks?: Track[] }) {
		tracks = tracks ?? this.getTracksBySection(sectionId);
		for (let index = 0; index < tracks.length; index++) {
			let track = tracks[index];
			track.reorder({ sectionId, sectionPosition: index + 1 });
		}
	}

	deleteTrackAndUpdatePosition(track: Track) {
		this.tracks = this.tracks.filter((t: Track) => t.id !== track.id);
		this.updatePosition({ sectionId: track.sectionId });
	}

	getSectionLength(sectionId: string): number {
		return this.getTracksBySection(sectionId).length || 0;
	}

	maxSectionLengthExceeded(sectionId: string): boolean {
		return this.getSectionLength(sectionId) >= 5;
	}

	getSectionPercentage(sectionId: string): number {
		const percentage = this.getSectionLength(sectionId) * 20;
		return percentage > 100 ? 100 : percentage;
	}

	getSectionPercentageAsString(sectionId: string): string {
		const percentage = this.getSectionPercentage(sectionId);
		return percentage === 100 ? '✔' : percentage + '%';
	}

	getTracksBySection(sectionId: string): Track[] {
		const tracks = this.tracks.filter((track: Track) => track.sectionId === sectionId);
		return tracks.sort((a, b) => a.sectionPosition - b.sectionPosition);
	}

	getTracksInScoringRangeBySection(sectionId: string): Track[] {
		let tracks = this.getTracksBySection(sectionId);
		return tracks.filter((track: Track) => track.isInScoringRange());
	}

	getSectionById(id: string): Section {
		return this.sectionsList.find((section: Section) => section.id === id);
	}

	getScore(): number {
		return this.score;
	}

	/**
	 * FIXME(score): Testen und aufteilen
	 */
	setScore() {
		let score: number = 0;

		// Calculate score for each track
		this.tracks.forEach((track: Track) => {
			score += track.getScore();
		});

		const top20Tracks: Track[] = this.getTracksInScoringRangeBySection('1').filter((track: Track) => track.scoreTags?.includes('MATCHES_SECTION'));
		if (top20Tracks.length === 5) {
			score += 10;
			this.setScoreTag('MATCHES_TOP_20_BINGO');
		}

		const top40Tracks: Track[] = this.getTracksInScoringRangeBySection('2').filter((track: Track) => track.scoreTags?.includes('MATCHES_SECTION'));
		if (top40Tracks.length === 5) {
			score += 10;
			this.setScoreTag('MATCHES_TOP_40_BINGO');
		}

		const top60Tracks: Track[] = this.getTracksInScoringRangeBySection('3').filter((track: Track) => track.scoreTags?.includes('MATCHES_SECTION'));
		if (top60Tracks.length === 5) {
			score += 10;
			this.setScoreTag('MATCHES_TOP_60_BINGO');
		}

		const top80Tracks: Track[] = this.getTracksInScoringRangeBySection('4').filter((track: Track) => track.scoreTags?.includes('MATCHES_SECTION'));
		if (top80Tracks.length === 5) {
			score += 10;
			this.setScoreTag('MATCHES_TOP_80_BINGO');
		}

		const top100Tracks: Track[] = this.getTracksInScoringRangeBySection('5').filter((track: Track) => track.scoreTags?.includes('MATCHES_SECTION'));
		if (top100Tracks.length === 5) {
			score += 10;
			this.setScoreTag('MATCHES_TOP_100_BINGO');
		}

		this.score = score;
	}

	setScoreTag(tag: string): void {
		if (!this.scoreTags) {
			this.scoreTags = [];
		}
		if (!this.scoreTags.includes(tag)) {
			this.scoreTags.push(tag);
		}
	}

	/**
	 * TODO(Track)
	 * Hier müssen jetzt die Tracks sortiert werden, so dass diese in der korrekten Reihenfolge angezeigt werden.
	 * Vorher nochmal überlegen ob es sinnvoll ist, die tracks in der Choice in sections aufzuteilen. Hätte den Nachteil,
	 * dasss man dynamische Felder hättte. Aber auch den Vorteil, das deutlich weniger Transformation notwendig ist.
	 */
	getSortedTracks(): Track[] {
		const trackList: Track[] = [];
		trackList.push(this.findTrackOrGenerateEmptyTrack({ trackList: this.tracks, sectionId: '5', sectionPosition: 1 }));
		trackList.push(this.findTrackOrGenerateEmptyTrack({ trackList: this.tracks, sectionId: '4', sectionPosition: 1 }));
		trackList.push(this.findTrackOrGenerateEmptyTrack({ trackList: this.tracks, sectionId: '3', sectionPosition: 1 }));
		trackList.push(this.findTrackOrGenerateEmptyTrack({ trackList: this.tracks, sectionId: '2', sectionPosition: 1 }));
		trackList.push(this.findTrackOrGenerateEmptyTrack({ trackList: this.tracks, sectionId: '1', sectionPosition: 1 }));

		trackList.push(this.findTrackOrGenerateEmptyTrack({ trackList: this.tracks, sectionId: '5', sectionPosition: 2 }));
		trackList.push(this.findTrackOrGenerateEmptyTrack({ trackList: this.tracks, sectionId: '4', sectionPosition: 2 }));
		trackList.push(this.findTrackOrGenerateEmptyTrack({ trackList: this.tracks, sectionId: '3', sectionPosition: 2 }));
		trackList.push(this.findTrackOrGenerateEmptyTrack({ trackList: this.tracks, sectionId: '2', sectionPosition: 2 }));
		trackList.push(this.findTrackOrGenerateEmptyTrack({ trackList: this.tracks, sectionId: '1', sectionPosition: 2 }));

		trackList.push(this.findTrackOrGenerateEmptyTrack({ trackList: this.tracks, sectionId: '5', sectionPosition: 3 }));
		trackList.push(this.findTrackOrGenerateEmptyTrack({ trackList: this.tracks, sectionId: '4', sectionPosition: 3 }));
		trackList.push(this.findTrackOrGenerateEmptyTrack({ trackList: this.tracks, sectionId: '3', sectionPosition: 3 }));
		trackList.push(this.findTrackOrGenerateEmptyTrack({ trackList: this.tracks, sectionId: '2', sectionPosition: 3 }));
		trackList.push(this.findTrackOrGenerateEmptyTrack({ trackList: this.tracks, sectionId: '1', sectionPosition: 3 }));

		trackList.push(this.findTrackOrGenerateEmptyTrack({ trackList: this.tracks, sectionId: '5', sectionPosition: 4 }));
		trackList.push(this.findTrackOrGenerateEmptyTrack({ trackList: this.tracks, sectionId: '4', sectionPosition: 4 }));
		trackList.push(this.findTrackOrGenerateEmptyTrack({ trackList: this.tracks, sectionId: '3', sectionPosition: 4 }));
		trackList.push(this.findTrackOrGenerateEmptyTrack({ trackList: this.tracks, sectionId: '2', sectionPosition: 4 }));
		trackList.push(this.findTrackOrGenerateEmptyTrack({ trackList: this.tracks, sectionId: '1', sectionPosition: 4 }));

		trackList.push(this.findTrackOrGenerateEmptyTrack({ trackList: this.tracks, sectionId: '5', sectionPosition: 5 }));
		trackList.push(this.findTrackOrGenerateEmptyTrack({ trackList: this.tracks, sectionId: '4', sectionPosition: 5 }));
		trackList.push(this.findTrackOrGenerateEmptyTrack({ trackList: this.tracks, sectionId: '3', sectionPosition: 5 }));
		trackList.push(this.findTrackOrGenerateEmptyTrack({ trackList: this.tracks, sectionId: '2', sectionPosition: 5 }));
		trackList.push(this.findTrackOrGenerateEmptyTrack({ trackList: this.tracks, sectionId: '1', sectionPosition: 5 }));
		return trackList;
	}

	findTrackOrGenerateEmptyTrack({ trackList, sectionId, sectionPosition }: { trackList: Track[]; sectionId: string; sectionPosition: number }): Track {
		const track = trackList.find((track) => track.sectionId === sectionId && track.sectionPosition === sectionPosition);
		return track ? track : Track.createPlaceholderTrack({ sectionId, sectionPosition });
	}

	hasStarted(): boolean {
		return this.round?.hasStarted();
	}

	getUserName(): string {
		return this.user?.username;
	}

	getSectionsWithoutPendingPicks(): any {
		return this.sectionsList.filter((section: Section) => !section.outOfScoreRange);
	}

	updateTrackScores(round: Round): void {
		const tracks: Track[] = this.tracks;
		for (const track of tracks) {
			const findResultTrack = round.results.find((result) => verifyIfArtistAndTrackMatches(track, result));
			track.setScore(findResultTrack);
		}
		this.setScore();
	}

	getResultPosition(choices: UserChoice[]): number {
		const index = choices.findIndex((choice) => choice.id === this.id);
		if (index === 0) {
			return 1;
		}
		const previous = choices[index - 1];
		if (previous.score === this.score) {
			return previous.getResultPosition(choices);
		}
		return index + 1;
	}

	isComplete(): { isComplete: boolean; tracksMissing: number; tracksMissingInSection1: number; tracksMissingInSection2: number; tracksMissingInSection3: number; tracksMissingInSection4: number; tracksMissingInSection5: number } {
		const tracksMissingInSection1: number = Math.max(0, 5 - (this.getTracksBySection('1').length || 0));
		const tracksMissingInSection2: number = Math.max(0, 5 - (this.getTracksBySection('2').length || 0));
		const tracksMissingInSection3: number = Math.max(0, 5 - (this.getTracksBySection('3').length || 0));
		const tracksMissingInSection4: number = Math.max(0, 5 - (this.getTracksBySection('4').length || 0));
		const tracksMissingInSection5: number = Math.max(0, 5 - (this.getTracksBySection('5').length || 0));
		const tracksMissing = tracksMissingInSection1 + tracksMissingInSection2 + tracksMissingInSection3 + tracksMissingInSection4 + tracksMissingInSection5;
		return { isComplete: tracksMissing === 0, tracksMissing, tracksMissingInSection1, tracksMissingInSection2, tracksMissingInSection3, tracksMissingInSection4, tracksMissingInSection5 };
	}
}

export interface UserChoiceVO extends Partial<UserChoice> {}
