<ul class="space-y-6">
	<li *ngFor="let reclamation of reclamations" class="p-6 border rounded-lg shadow-lg bg-white">
		<div class="flex justify-between items-center mb-4">
			<div class="text-xl font-semibold text-gray-700">
				Username: <span class="text-primary">{{ reclamation.user.username }}</span>
			</div>
			<div class="text-lg text-gray-500">
				Round: <span class="text-secondary">{{ reclamation.round.name }}</span>
			</div>
		</div>

		<div class="grid grid-cols-2 gap-4 mb-6">
			<div>
				<p class="text-lg font-medium text-gray-800">Track Name:</p>
				<p class="text-lg text-gray-600">{{ reclamation.track.name }}</p>
				<p class="text-lg font-medium text-gray-800 mt-2">Track Artist:</p>
				<p class="text-lg text-gray-600">{{ reclamation.track.artist }}</p>
			</div>
			<div>
				<p class="text-lg font-medium text-gray-800">Result Track:</p>
				<p class="text-lg text-gray-600">{{ reclamation.resultTrack.track }}</p>
				<p class="text-lg font-medium text-gray-800 mt-2">Result Artist:</p>
				<p class="text-lg text-gray-600">{{ reclamation.resultTrack.artist }}</p>
			</div>
		</div>

		<div class="flex flex-wrap justify-end gap-2">
			<button class="btn w-full sm:w-auto" (click)="onMatchTrack(reclamation)">Bestätigen</button>
			<button class="btn w-full sm:w-auto" (click)="onAddArtistSynonym(reclamation)">Artist Synonym hinzufügen</button>
			<button class="btn w-full sm:w-auto" (click)="onAddTrackSynonym(reclamation)">Track Synonym hinzufügen</button>
			<button class="btn w-full sm:w-auto" (click)="onDeleteReclamation(reclamation)">Löschen</button>
		</div>
	</li>
</ul>
