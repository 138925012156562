import { Component, OnInit } from '@angular/core';
import { UserChoiceService } from '../../../services/user-choice.service';
import { UserChoiceReclamation } from '../../../../../shared/model/user-choice-reclamation';
import { firstValueFrom, Subscription } from 'rxjs';
import { OverlayService } from '../../../services/overlay.service';
import { RoundService } from '../../../services/round.service';
import { RoundResult } from '../../../../../shared/model/round-result';
import { AngularFireFunctions } from '@angular/fire/compat/functions';

@Component({
	selector: 'app-user-choice-reclamation',
	templateUrl: './user-choice-reclamation.component.html',
	styleUrls: ['./user-choice-reclamation.component.css']
})
export class UserChoiceReclamationComponent implements OnInit {
	reclamations: UserChoiceReclamation[] = [];
	constructor(private userChoiceService: UserChoiceService, private overlayService: OverlayService, private roundService: RoundService, private functions: AngularFireFunctions) {}

	ngOnInit() {
		this.userChoiceService.getChoiceReclamations().subscribe(async (reclamations: UserChoiceReclamation[]) => {
			for (const reclamation of reclamations) {
				reclamation.userChoice = await firstValueFrom(this.userChoiceService.getOne({ id: reclamation.choiceRef.id, user: reclamation.user }));
				const track = reclamation.userChoice.tracks.find((track) => track.id === reclamation.trackId);
				if (!track.matchingTrackId) {
					this.reclamations.push(reclamation);
				}
			}
		});
	}

	onMatchTrack(reclamation: UserChoiceReclamation): Subscription {
		const callable = this.functions.httpsCallable('matchTrack');
		return callable({ choiceRef: reclamation.choiceRef.path, resultTrackId: reclamation.resultTrackId, trackId: reclamation.trackId }).subscribe({
			next: (result) => {
				this.overlayService.snack({ message: 'Song wurde erfolgreich zugeordnet.' });
			},
			error: (error) => {
				this.overlayService.error({ message: 'Es ist ein unerwarteter Fehler aufgetreten.' });
			}
		});
	}

	async onAddArtistSynonym(reclamation: UserChoiceReclamation) {
		const choice = reclamation.userChoice;
		const round = await firstValueFrom(this.roundService.getRound(reclamation.round.id));
		const track = choice.tracks.find((track) => track.id === reclamation.trackId);

		const callable = this.functions.httpsCallable('addArtistOrTrackSynonym');
		return callable({ roundRef: `rounds/${round.id}`, resultTrackId: reclamation.resultTrackId, artistSynonym: track.artist }).subscribe({
			next: (result) => {
				this.overlayService.snack({ message: 'Song wurde erfolgreich zugeordnet.' });
			},
			error: (error) => {
				this.overlayService.error({ message: 'Es ist ein unerwarteter Fehler aufgetreten.' });
			}
		});
	}

	async onAddTrackSynonym(reclamation: UserChoiceReclamation) {
		const choice = reclamation.userChoice;
		const round = await firstValueFrom(this.roundService.getRound(reclamation.round.id));
		const track = choice.tracks.find((track) => track.id === reclamation.trackId);

		const callable = this.functions.httpsCallable('addArtistOrTrackSynonym');
		return callable({ roundRef: `rounds/${round.id}`, resultTrackId: reclamation.resultTrackId, trackSynonym: track.name }).subscribe({
			next: (result) => {
				this.overlayService.snack({ message: 'Song wurde erfolgreich zugeordnet.' });
			},
			error: (error) => {
				this.overlayService.error({ message: 'Es ist ein unerwarteter Fehler aufgetreten.' });
			}
		});
	}

	onDeleteReclamation(reclamation: UserChoiceReclamation) {
		this.overlayService.snack({ message: 'Funktion muss noch implementiert werden.' });
	}
}
