import { Track } from '../../../shared/model/track';
import { RoundResult } from '../../../shared/model/round-result';
import { LodashUtil } from '../../../shared/util/lodash.util';

export const verifyIfArtistAndTrackMatches = (track: Track, result: RoundResult): boolean => {
	if (track.matchingTrackId?.length > 0 && track.matchingTrackId === result.id) {
		return true;
	}
	const trackMatches = verifyIfStringsMatch(track.name, result.track) || verifyIfTrackIsIncludedInSynonyms(track, result);
	const artistMatches = verifyIfStringsMatch(track.artist, result.artist) || verifyIfArtistIsIncludedInSynonyms(track, result);
	return trackMatches && artistMatches;
};

export const verifyIfStringsMatch = (str1: string, str2: string): boolean => {
	if (!(LodashUtil.isString(str1) && LodashUtil.isString(str2))) {
		return false;
	}
	str1 = removeSpecialCharacters(str1);
	str2 = removeSpecialCharacters(str2);
	return str1?.includes(str2) || str2?.includes(str1);
};

const verifyIfTrackIsIncludedInSynonyms = (track: Track, result: RoundResult): boolean => {
	return result.trackSynonyms?.some((synonym) => synonym === track.name);
};

const verifyIfArtistIsIncludedInSynonyms = (track: Track, result: RoundResult): boolean => {
	return result.artistSynonyms?.some((synonym) => synonym === track.artist);
};

const removeSpecialCharacters = (str: string): string => {
	return str
		.normalize('NFD') // normalize the string
		.replace(/[\u0300-\u036f]/g, '') // remove diacritical marks
		.toLowerCase()
		.replace(/[^a-z0-9]/g, '');
};
