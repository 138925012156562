import { NgModule } from '@angular/core';
import { ChoiceRoutingModule } from './choice-routing.module';
import { UiModule } from '../ui/ui.module';
import { UserChoiceViewComponent } from './user-choice-view/user-choice-view.component';
import { UserChoiceEditListComponent } from './user-choice-edit-list/user-choice-edit-list.component';
import { RoundResultView } from './round-result-view/round-result-view.component';
import { HighscoreComponent } from './highscore/highscore.component';
import { CdkModule } from '../ui/cdk.module';
import { FormControlModule } from '../form-control/form-control.module';
import { RoundUserChoicesViewComponent } from './round-user-choices-view/round-user-choices-view.component';
import { UserChoiceEditGridComponent } from './user-choice-edit-grid/user-choice-edit-grid.component';
import { TrackCreateComponent } from './track-create/track-create.component';
import { RoundSummaryComponent } from './round-summary/round-summary.component';
import { UserChoiceEditComponent } from './user-choice-edit/user-choice-edit.component';
import { SectionSelectorComponent } from './track-create/section-selector.component';

@NgModule({
	declarations: [UserChoiceViewComponent, UserChoiceEditListComponent, RoundUserChoicesViewComponent, RoundResultView, RoundSummaryComponent, SectionSelectorComponent, TrackCreateComponent, UserChoiceEditComponent, UserChoiceEditGridComponent, HighscoreComponent],
	imports: [ChoiceRoutingModule, UiModule, FormControlModule, CdkModule]
})
export class ChoiceModule {}
