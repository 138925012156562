import { NgModule } from '@angular/core';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DashboardRoutingModule } from './dashboard-routing.module';
import { UiModule } from '../ui/ui.module';
import { FormControlModule } from '../form-control/form-control.module';
import { IntroductionComponent } from './introduction/introduction.component';

@NgModule({
	declarations: [DashboardComponent, IntroductionComponent],
	imports: [DashboardRoutingModule, UiModule, FormControlModule]
})
export class DashboardModule {}
