import { NgModule } from '@angular/core';
import { LoginComponent } from './login/login.component';
import { LoginRoutingModule } from './login-routing.module';
import { RegisterComponent } from './register/register.component';
import { UiModule } from '../ui/ui.module';
import { FormControlModule } from '../form-control/form-control.module';

@NgModule({
	declarations: [LoginComponent, RegisterComponent],
	imports: [LoginRoutingModule, FormControlModule, UiModule]
})
export class LoginModule {}
