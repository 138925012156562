import { Component } from '@angular/core';
import { Round } from '../../../../../shared/model/round';
import { RoundService } from '../../../services/round.service';
import { SessionService } from '../../../services/session.service';
import { UserChoiceService } from '../../../services/user-choice.service';
import { User } from '../../../../../shared/model/user';
import { UserChoice } from '../../../../../shared/model/user-choice';

class UserSummary {
	userId: string;
	username: string;
	overallScore: number = 0;
	numberOnePlaces: number = 0;
	numberTwoPlaces: number = 0;
	numberThreePlaces: number = 0;
	gamesPlayed: number = 0;
	choiceIds: string[] = [];

	static createUserSummary(user: User): UserSummary {
		const userSummary = new UserSummary();
		userSummary.userId = user.id;
		userSummary.username = user.username;
		return userSummary;
	}

	addChoiceScore(choice: UserChoice): void {
		if (this.choiceIds.includes(choice.id)) {
			return;
		}
		this.choiceIds.push(choice.id);
		this.gamesPlayed++;
		this.overallScore += choice.getScore();
		if (choice.place === 1) {
			this.numberOnePlaces++;
		} else if (choice.place === 2) {
			this.numberTwoPlaces++;
		} else if (choice.place === 3) {
			this.numberThreePlaces++;
		}
	}

	getAverageScore(): number {
		if (this.gamesPlayed < 1) {
			return 0;
		}
		return Math.round((this.overallScore / this.gamesPlayed) * 10) / 10;
	}
}

@Component({
	selector: 'app-highscore',
	templateUrl: './highscore.component.html',
	styleUrls: ['./highscore.component.css']
})
export class HighscoreComponent {
	userSummaries: UserSummary[] = [];

	constructor(private roundService: RoundService, private userChoiceService: UserChoiceService, private sessionService: SessionService) {}

	ngOnInit() {
		this.initializeUsers();
	}

	initializeUsers() {
		const communityRefPath = this.sessionService.getCommunityReferencePath();
		this.roundService.getRoundsByCommunity({ communityRefPath: communityRefPath }).subscribe((rounds: Round[]) => {
			for (const round of rounds) {
				// TODO: Hier muss ein Filter eingebaut werden!
				if (round.startsAt.getFullYear() !== 2024) {
					continue;
				}
				this.userChoiceService.getUserChoicesByRound({ round }).subscribe((userChoices) => {
					for (const choice of userChoices) {
						if (!choice.place) {
							continue;
						}
						let summary = this.userSummaries.find((summary) => summary.userId === choice.user.id);
						if (!summary) {
							summary = UserSummary.createUserSummary(choice.user);
							this.userSummaries.push(summary);
						}
						summary.addChoiceScore(choice);
					}
					this.userSummaries.sort((a, b) => b.overallScore - a.overallScore);
				});
			}
		});
	}
}
