<div *ngIf="userChoice">
	<app-round-summary [round]="userChoice.round"></app-round-summary>

	<div class="px-safe">
		<div class="navbar w-full">
			<div class="navbar-start">
				<div class="join border center">
					<button class="btn btn-ghost join-item" [ngClass]="{ 'text-primary bg-gray-100': mode === 'grid' }" (click)="switchMode('grid')">
						<mat-icon>grid_on</mat-icon>
					</button>
					<button class="btn btn-ghost join-item" [ngClass]="{ 'text-primary bg-gray-100': mode === 'list' }" (click)="switchMode('list')">
						<mat-icon>list</mat-icon>
					</button>
				</div>
			</div>
			<!--			<div class="navbar-center">-->
			<!--				<button class="btn btn-circle btn-ghost hover:bg-gray-700" (click)="onQuestion()">-->
			<!--					<mat-icon>lightbulb_outline</mat-icon>-->
			<!--				</button>-->
			<!--			</div>-->
			<div class="navbar-end">
				<button class="btn btn-ghost font-normal" (click)="openAddTrackDialog()">
					<mat-icon>add_circle_outline</mat-icon>
					<span>Song hinzufügen</span>
				</button>
			</div>
		</div>
		<app-user-choice-edit-list *ngIf="mode === 'list'" [userChoice]="userChoice" (trackAdded)="openAddTrackDialog($event)" (trackSelected)="openTrackSelectedDialog($event)"></app-user-choice-edit-list>
		<app-user-choice-edit-grid *ngIf="mode === 'grid'" [userChoice]="userChoice" (trackAdded)="openAddTrackDialog($event)" (trackSelected)="openTrackSelectedDialog($event)"></app-user-choice-edit-grid>
	</div>
</div>

<ng-template #help>
	<app-dialog-header></app-dialog-header>
	<div class="dialog-content mat-typography">
		<div class="hero bg-base-200">
			<div class="hero-content flex-col lg:flex-row-reverse">
				<div>
					<h2 class="text-3xl font-bold">Die Song Auswahl</h2>
					<p class="py-6">In dieser Ansicht kannst die Songs hinzufügen, entfernen und verschieben. Du kannst zwischen zwei Modi wechseln: der Bingoansicht und der Listenansicht.</p>
					<h2 class="text-xl font-bold">Bingoansicht</h2>
					<img src="assets/images/demo/user-choice-edit-grid-small.png" class="max-w-sm rounded-lg shadow-2xl" />
				</div>
			</div>
		</div>
	</div>
</ng-template>

<ng-template #trackDetail>
	<app-dialog-header></app-dialog-header>
	<div class="dialog-content mat-typography">
		<div class="text-center">
			<span class="font-bold">{{ userChoice.getSectionById(selectedTrack.sectionId).name }}. </span>
			<span class="font-bold">{{ selectedTrack.name }}</span>
			<span class="text-gray-500"> – </span>
			<span class="italic">{{ selectedTrack.artist }}</span>
		</div>
		<div class="button-container flex justify-center space-x-4">
			<div *ngIf="userChoice.user?.canUseSpotify()" class="player-controls flex items-center space-x-4">
				<button class="btn btn-primary" (click)="play()">
					<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="h-6 w-6">
						<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 19L18 12 6 5z"></path>
					</svg>
				</button>
			</div>
			<div>
				<button class="btn btn-ghost btn-circle text-gray-400 hover:text-current" (click)="openDeleteTrackDialog(selectedTrack)">
					<mat-icon>delete</mat-icon>
				</button>
			</div>
		</div>
		<!-- TODO: Generell schöne Idee hier, den Track zu ändern. Muss noch umgesetzt werden. Dann muss aber alles resetted werden, z.B. Spotify -->
		<!-- <form #addTrackForm="ngForm" class="flex flex-col gap-3">
			<div class="p-2 w-full h-full flex gap-1 border-b"></div>
			<div class="flex items-center">
				<label class="mr-2">Verschieben</label>
				<app-section-selector [(sectionId)]="selectedTrack.sectionId" [userChoice]="userChoice"></app-section-selector>
				<button class="btn btn-ghost font-normal" (click)="updateTrackSection(selectedTrack)">
					<mat-icon>add_circle_outline</mat-icon>
					<span>Speichern</span>
				</button>
			</div>
		</form>-->
	</div>
</ng-template>
