import { Component, Input } from '@angular/core';
import { Dialog } from '@angular/cdk/dialog';

@Component({
	selector: 'app-dialog-header',
	templateUrl: './dialog-header.component.html',
	styleUrls: ['./dialog-header.component.css']
})
export class DialogHeaderComponent {
	@Input()
	title: string = null;

	constructor(private dialog: Dialog) {}

	onClose() {
		this.dialog.closeAll();
	}
}
