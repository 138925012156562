import { AfterViewInit, Component, TemplateRef, ViewChild } from '@angular/core';
import { filter, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { SwUpdate, VersionEvent } from '@angular/service-worker';
import { SessionService } from './services/session.service';
import { User } from '../../shared/model/user';
import { IntroductionComponent } from './modules/dashboard/introduction/introduction.component';
import { OverlayService } from './services/overlay.service';

@Component({
	selector: 'app-root, [app-root]',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css']
})
export class AppComponent implements AfterViewInit {
	user$: Observable<User>;
	@ViewChild('menu') menuDialog!: TemplateRef<any>;

	constructor(private sessionService: SessionService, private overlayService: OverlayService, private router: Router, private swUpdate: SwUpdate) {}

	async ngAfterViewInit() {
		this.user$ = this.sessionService.selectUser();
		await this.registerServiceWorkerUpdate();
	}

	async onLogout(): Promise<void> {
		this.onMenuItemClick();
		this.overlayService.confirm({ title: 'Abmelden', message: `Sicher, dass du dich abmelden möchtest?` }, async () => {
			await this.sessionService.logout();
			await this.router.navigate(['/login']);
		});
	}

	async onQuestion(): Promise<void> {
		this.overlayService.open(IntroductionComponent);
	}

	onMenuClick() {
		this.overlayService.open(this.menuDialog);
	}

	async registerServiceWorkerUpdate() {
		if (this.swUpdate.isEnabled) {
			this.swUpdate.versionUpdates
				.pipe(
					filter((versionEvent: VersionEvent) => versionEvent.type === 'VERSION_READY')
					// map((evt: VersionEvent) => ({
					//     type: 'UPDATE_AVAILABLE',
					//     current: evt.currentVersion,
					//     available: evt.latestVersion
					// }))
				)
				.subscribe(async () => {
					this.overlayService.confirm(
						{
							message: `Eine neue Version ist verfügbar. Möchtest du die Anwendung neu laden?`,
							confirmButtonText: 'Ja',
							cancelButtonVisible: false
						},
						async () => {
							await this.onUpdate();
						}
					);
				});
			await this.swUpdate.checkForUpdate();
		}
	}

	async onUpdate() {
		await this.swUpdate.activateUpdate();
		this.reload();
	}

	reload() {
		document.location.reload();
	}

	onMenuItemClick() {
		this.overlayService.closeAll();
	}
}
