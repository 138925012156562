import { Community } from './community';
import { User } from './user';

export enum MembershipRole {
	ADMIN = 'admin',
	MEMBER = 'member'
}

export class Membership {
	community: Community;
	communityRef: any;
	user: User;
	userRef: any;
	role: MembershipRole;

	constructor(vo: any) {
		return Object.assign(this, vo);
	}

	static toFirestore(vo: MembershipVO): object {
		return {
			communityRef: vo.communityRef,
			userRef: vo.userRef,
			role: vo.role,
			user: {
				id: vo.user?.id,
				username: vo.user?.username
			},
			community: {
				id: vo.community?.id,
				name: vo.community?.name
			}
		};
	}
}

export interface MembershipVO extends Partial<Membership> {}
