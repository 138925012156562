import { FirestoreReference } from './firestore-reference';
import { Round } from './round';
import { Track } from './track';
import { User } from './user';
import { RoundResult } from './round-result';
import { UserChoice } from './user-choice';

class UserChoiceReclamationFirestore {
	choiceRef: FirestoreReference;
	userRef: FirestoreReference;
	round: Round;
	resultTrackId: string;
	trackId: string;
	track: Track;
	user: User;
	resultTrack: RoundResult;

	constructor(vo: any) {
		Object.assign(this, vo);
	}
}

export class UserChoiceReclamation extends UserChoiceReclamationFirestore {
	id: string;
	userChoice: UserChoice;

	constructor(vo: any) {
		super(vo);
		this.id = vo.id;
	}
}
