import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Round } from '../../../shared/model/round';
import { map } from 'rxjs/operators';
import { Track } from '../../../shared/model/track';
import { AngularFirestore, AngularFirestoreDocument, DocumentReference } from '@angular/fire/compat/firestore';
import * as firestoreUtil from '../util/firestore.util';
import { UserChoice, UserChoiceVO } from '../../../shared/model/user-choice';
import { FirestoreUser, User } from '../../../shared/model/user';
import { FirestoreService } from './firestore.service';
import { RoundResult } from '../../../shared/model/round-result';
import { UserChoiceReclamation } from '../../../shared/model/user-choice-reclamation';

/**
 * TODO(Track): Allgemeinen Weg finden: Tracks dürfen nur hinzugefügt werden, wenn Runde noch nicht gestartet ist. Ein einfacher Weg wäre über functions zu gehen.
 */
@Injectable({
	providedIn: 'root'
})
export class UserChoiceService {
	constructor(private firestore: AngularFirestore, private firestoreService: FirestoreService) {}

	public getOne({ id, user }: { id: string; user: User }): Observable<UserChoice> {
		return this.firestoreService.selectOne<FirestoreUser>('user-choices', id).pipe(
			map((snapshot) => {
				const data = this.firestoreService.getData(snapshot);
				return new UserChoice({ id: snapshot.payload.id, ...data, user });
			})
		);
	}

	getUserChoiceByRound({ user, round }: { user: User; round: Round }): Observable<UserChoice | undefined> {
		const roundRef: AngularFirestoreDocument = this.firestore.doc(`rounds/${round.id}`);
		const userRef: AngularFirestoreDocument = this.firestore.doc(`users/${user.id}`);

		const userChoiceRef = this.firestore.collection('user-choices', (ref) => ref.where('roundRef', '==', roundRef.ref).where('userRef', '==', userRef.ref));
		return userChoiceRef.snapshotChanges().pipe(
			map((changes: any) => {
				const firstChange = changes[0];
				if (!firstChange) {
					return undefined;
				}
				const userChoice = new UserChoice({ id: firstChange.payload.doc.id, ...firstChange.payload.doc.data() });
				userChoice.user = user;
				userChoice.round = round;
				return userChoice;
			})
		);
	}

	getUserChoicesByUser({ user }: { user: User }): Observable<UserChoice[]> {
		const userRef: AngularFirestoreDocument = this.firestore.doc(`users/${user.id}`);

		const userChoiceRef = this.firestore.collection('user-choices', (ref) => ref.where('userRef', '==', userRef.ref));
		return userChoiceRef.snapshotChanges().pipe(
			map((changes: any) => {
				return changes.map((change: any) => {
					const userChoice = new UserChoice({ id: change.payload.doc.id, ...change.payload.doc.data() });
					userChoice.user = user;
					return userChoice;
				});
			})
		);
	}

	getUserChoicesByRound({ round }: { round: Round }): Observable<UserChoice[]> {
		const roundRef: AngularFirestoreDocument = this.firestore.doc(`rounds/${round.id}`);

		const userChoiceRef = this.firestore.collection('user-choices', (ref) => ref.where('roundRef', '==', roundRef.ref));
		return userChoiceRef.snapshotChanges().pipe(
			map((changes: any) => {
				return changes.map((change: any) => {
					return new UserChoice({ id: change.payload.doc.id, ...change.payload.doc.data() });
				});
			})
		);
	}

	/**
	 * TODO: Implement filter existingRoundIds
	 * @param communityRefPath
	 * @param existingRoundIds
	 */
	getRoundsWithoutUserChoice({ communityRefPath, existingRoundIds }: { communityRefPath: string; existingRoundIds: string[] }): Observable<Round[]> {
		const communityRefDoc = this.firestore.doc(communityRefPath);
		const reference = this.firestore.collection('rounds', (ref) => ref.where('communityRef', '==', communityRefDoc.ref));
		return reference.snapshotChanges().pipe(
			map((changes: any) => {
				return changes.map((change: any) => {
					return new Round({ id: change.payload.doc.id, ...change.payload.doc.data() });
				});
			})
		);
	}

	/**
	 * TODO(security-rule):
	 * Das muss abgesichert werden. Ggf. Logik zum Create in functions schieben.
	 * @param vo
	 */
	createUserChoice(vo: UserChoiceVO): Promise<DocumentReference> {
		return this.firestore.collection('user-choices').add(vo);
	}

	updateUserChoice(choice: UserChoice): Promise<void> {
		const choiceRef = this.firestore.collection('user-choices').doc(choice.id);
		return choiceRef.update({ tracks: choice.tracks.map((track) => firestoreUtil.getData(track)) });
	}

	async updateUserChoiceReclaimPointsForTrack({ choice, track, result }: { choice: UserChoice; track: Track; result: RoundResult }): Promise<void> {
		const choiceRef = this.firestore.collection('user-choices').doc(choice.id);
		const userRef = this.firestore.collection('users').doc(choice.user.id);

		const collection = this.firestore.collection(`reclamations`);
		// FIXME: fields
		await collection.add({
			choiceRef: choiceRef.ref,
			userRef: userRef.ref,
			user: firestoreUtil.getData(choice.user),
			round: firestoreUtil.getData(choice.round),
			resultTrack: firestoreUtil.getData(result),
			resultTrackId: result.id,
			track: track.toObject(),
			trackId: track.id
		});
	}

	getChoiceReclamations(): Observable<any> {
		const collection = this.firestore.collection(`reclamations`);
		return collection.snapshotChanges().pipe(
			map((changes: any) => {
				return changes.map((change: any) => {
					return new UserChoiceReclamation({ id: change.payload.doc.id, ...change.payload.doc.data() });
				});
			})
		);
	}

	async deleteTrackFromUserChoice({ choice, track }: { choice: UserChoice; track: Track }): Promise<void> {
		choice.deleteTrackAndUpdatePosition(track);

		const choiceRef = this.firestore.collection('user-choices').doc(choice.id);
		const tracks = choice.tracks.filter((t) => t.id !== track.id);
		return choiceRef.update({ tracks: tracks.map((t) => firestoreUtil.getData(t)) });
	}
}
