<div *ngIf="!results || results.length < 1" class="px-safe">
	<div class="p-3">
		<div class="text-center text-gray-500">Für diese Runde liegen noch keine Ergebnisse vor.</div>
	</div>
</div>
<div *ngIf="results.length > 0" class="px-safe">
	<div class="p-3">
		<h2 class="text-center text-xl font-bold mb-3">Top 100</h2>
		<div *ngFor="let result of results; let i = index">
			<div class="p-2 w-full h-full flex gap-1 border-b">
				<span class="font-bold min-w-8">{{ result.position }}.</span>
				<span class="italic">{{ result.track }}</span> <span class="text-gray-500"> – </span> <span class="italic">{{ result.artist }}</span>
			</div>
		</div>
	</div>
</div>
