import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { FirebaseUser } from '../model/firebase-user';
import { Observable, switchMap } from 'rxjs';
import { map } from 'rxjs/operators';
import { FirestoreUser, User } from '../../../shared/model/user';
import { FirestoreService } from './firestore.service';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { MembershipService } from '../modules/community/services/membership.service';

@Injectable({
	providedIn: 'root'
})
export class UserService {
	constructor(private firestore: AngularFirestore, private firestoreService: FirestoreService, private functions: AngularFireFunctions, private membershipService: MembershipService) {}

	getCommunityUsers({ communityRef }: { communityRef: string }): Observable<User[]> {
		const callable = this.functions.httpsCallable('getUsersByCommunity');
		return callable({ communityRef: communityRef });
	}

	public selectOne(id: string): Observable<User> {
		return this.firestoreService.selectOne<FirestoreUser>('users', id).pipe(
			map((snapshot) => {
				const data = this.firestoreService.getData(snapshot);
				return new User({ id: snapshot.payload.id, ...data });
			})
		);
	}

	public selectOneWithMemberships(uid: string): Observable<User> {
		return this.selectOne(uid).pipe(
			switchMap((user: User) => {
				return this.membershipService.getMembershipsByUser(uid).pipe(
					map((memberships) => {
						user.setMemberships(memberships);
						return user;
					})
				);
			})
		);
	}

	async storeUser({ username, firebaseUser }: { username: string; firebaseUser: FirebaseUser }): Promise<void> {
		const collection = this.firestore.collection(`users`);
		await collection.doc(firebaseUser.uid).set({
			username: username
		});
	}
}
