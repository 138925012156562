import { Injectable } from '@angular/core';
import * as localForage from 'localforage';
import { Community } from '../../../shared/model/community';

enum StorageKey {
	COMMUNITY = 'community',
	SPOTIFY_ACCESS_TOKEN = 'spotify-access-token'
}

@Injectable({
	providedIn: 'root'
})
export class StorageService {
	constructor() {}

	async setCommunity(community: Community): Promise<void> {
		return this.setItem(StorageKey.COMMUNITY, JSON.parse(JSON.stringify(community)));
	}

	async getCommunity(): Promise<Community> {
		const community = await this.getItem(StorageKey.COMMUNITY);
		return community ?? null;
	}

	setSpotifyAccessToken(accessToken: string) {
		return this.setItem(StorageKey.SPOTIFY_ACCESS_TOKEN, accessToken);
	}

	async getSpotifyAccessToken(): Promise<string> {
		return this.getItem(StorageKey.SPOTIFY_ACCESS_TOKEN);
	}

	private async setItem(key: StorageKey, value: any): Promise<void> {
		return localForage.setItem(key, value);
	}

	private async getItem(key: StorageKey): Promise<any> {
		return localForage.getItem(key);
	}

	private async removeItem(key: StorageKey): Promise<void> {
		return localForage.removeItem(key);
	}
}
