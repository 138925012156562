import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { map, switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { UserChoiceService } from '../../../services/user-choice.service';
import { SessionService } from '../../../services/session.service';
import { UserChoice } from '../../../../../shared/model/user-choice';
import { TrackCreateComponent } from '../track-create/track-create.component';
import { Track, TrackVO } from '../../../../../shared/model/track';
import { ConfirmationDialogComponent } from '../../ui/confirmation-dialog/confirmation-dialog.component';
import { SpotifyPlayerService } from '../../settings/spotify-player.service';
import { Section } from '../../../../../shared/model/section';
import { OverlayService } from '../../../services/overlay.service';
import { Overlay } from '@angular/cdk/overlay';

@Component({
	selector: 'app-user-choice-edit',
	templateUrl: './user-choice-edit.component.html',
	styleUrls: ['./user-choice-edit.component.css']
})
export class UserChoiceEditComponent implements OnInit {
	userChoice: UserChoice;
	mode: string = 'grid';
	selectedTrack: Track;
	@ViewChild('help') helpDialog!: TemplateRef<any>;
	@ViewChild('trackDetail') trackDetailDialog!: TemplateRef<any>;

	constructor(private activatedRoute: ActivatedRoute, private userChoiceService: UserChoiceService, private sessionService: SessionService, private overlayService: OverlayService, private spotifyService: SpotifyPlayerService, private overlay: Overlay) {}

	ngOnInit(): void {
		this.loadData();
	}

	protected loadData() {
		this.getUserChoiceByRoundParam().subscribe((userChoice: UserChoice) => {
			this.userChoice = userChoice;
		});
	}

	private getUserChoiceByRoundParam(): Observable<UserChoice> {
		const user = this.sessionService.getUser();
		return this.activatedRoute.paramMap.pipe(
			map((params) => params.get('id')),
			switchMap((id: string) => this.userChoiceService.getOne({ id, user }))
		);
	}

	openAddTrackDialog(sectionId?: string): void {
		this.overlayService.closeAll();
		const dialogRef = this.overlayService.openOnTopWithNoBackdrop(TrackCreateComponent, {
			data: { userChoice: this.userChoice, sectionId }
		});

		const choice: UserChoice = this.userChoice;
		dialogRef.closed.subscribe(async (vo: TrackVO) => {
			try {
				if (vo) {
					choice.addTrack(vo);
					await this.userChoiceService.updateUserChoice(choice);
					this.overlayService.snack({ message: `Der Song ${vo.name} (${vo.artist}) wurde erfolgreich hinzugefügt.`, action: 'Alles klar!', duration: 2000 });
					this.openAddTrackDialog(vo.sectionId);
				}
			} catch (e: any) {
				if (e.code === 'permission-denied') {
					this.overlayService.error({ message: `Du bist nicht berechtigt, den Song hinzuzufügen.` });
				} else {
					this.overlayService.error({ message: `Es ist ein unerwarteter Fehler aufgetreten, es ist ein unerwarteter Fehler aufgetreten.` });
				}
			}
		});
	}

	openTrackSelectedDialog(track: Track): void {
		this.selectedTrack = track;
		this.overlayService.closeAll();
		this.overlayService.openOnTopWithNoBackdrop(this.trackDetailDialog);
	}
	openDeleteTrackDialog(track: Track): void {
		this.overlayService.closeAll();
		const dialogRef = this.overlayService.open(ConfirmationDialogComponent, {
			data: {
				message: `<b>${track.name}</b> – <i>${track.artist}</i>`,
				title: 'Song entfernen',
				confirmButtonText: 'Entfernen',
				cancelButtonText: 'Behalten'
			}
		});
		const userChoice = this.userChoice;
		dialogRef.closed.subscribe((confirmed: boolean) => {
			if (confirmed) {
				this.userChoiceService
					.deleteTrackFromUserChoice({ choice: userChoice, track })
					.then(async () => {
						this.overlayService.snack({ message: 'Song erfolgreich entfernt.', action: 'Alles klar!', duration: 2000 });
					})
					.catch((e: any) => {
						if (e.code === 'permission-denied') {
							this.overlayService.error({ message: `Du bist nicht berechtigt, den Song zu entfernen.` });
						} else {
							this.overlayService.error({ message: `Es ist ein unerwarteter Fehler aufgetreten, der Song konnte nicht gelöscht werden.` });
						}
					});
			}
		});
	}

	switchMode(mode: string): void {
		this.mode = mode;
	}

	onQuestion() {
		this.overlayService.open(this.helpDialog);
	}

	async updateTrackSection(track: Track) {
		await this.userChoiceService.updateUserChoice(this.userChoice);
	}

	async play() {
		await this.spotifyService.playTrack(this.selectedTrack);
	}

	protected readonly Section = Section;
}
