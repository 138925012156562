import { Component, OnInit } from '@angular/core';
import { SpotifyPlayerService } from '../spotify-player.service';
import { SpotifyAuthorize } from '../../../util/spotify-authorize';

@Component({
	selector: 'app-spotify-account',
	templateUrl: './spotify-account.component.html',
	styleUrls: ['./spotify-account.component.css']
})
export class SpotifyAccountComponent implements OnInit {
	profile: any;
	constructor(private spotifyService: SpotifyPlayerService) {}

	async ngOnInit() {
		await this.loadData();
	}

	async loadData() {
		this.profile = await this.spotifyService.getMyProfile();
	}

	redirectToAuthorize() {
		const spotifyAuthorize = new SpotifyAuthorize();
		const url = spotifyAuthorize.createAuthorizeURL();
		window.location.href = url;
	}
}
