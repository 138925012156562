<ng-container *ngIf="userChoice">
	<div cdkDropListGroup>
		<div cdkDropList *ngFor="let section of userChoice.sectionsList; let i = index" [id]="section.id" cdkDropListAutoScrollStep="20" (cdkDropListDropped)="drop($event)" [cdkDropListData]="userChoice.getTracksBySection(section.id)" class="drag-and-drop-list">
			<div class="bg-gray-200 flex items-center justify-between p-3 hover:bg-gray-300" (click)="onTrackAdded(section.id)">
				<div class="flex-1 text-center text-sm font-bold">
					<span>{{ section.name }}</span>
				</div>
				<div *ngIf="!section.outOfScoreRange" class="flex-none">
					<div class="radial-progress" style="--value: {{ userChoice.getSectionPercentage(section.id) }}; --size: 2.2rem; --thickness: 1.3px" role="progressbar">
						{{ userChoice.getSectionPercentageAsString(section.id) }}
					</div>
				</div>
				<div *ngIf="section.outOfScoreRange" class="flex-none" style="width: 2.2rem"></div>
			</div>

			<div *ngFor="let track of userChoice.getTracksBySection(section.id); let i = index" cdkDrag [ngClass]="{ 'track-out-of-scoring-range': section.outOfScoreRange === true || track.isOutOfScoringRange() }" class="border-b px-3 py-1 flex gap-3 items-center">
				<div cdkDragHandle class="text-gray-400 hover:text-current cursor-pointer">
					<mat-icon>drag_handle</mat-icon>
				</div>
				<div>
					<span class="italic">{{ track.name }}</span>
					<span class="text-gray-500"> – </span>
					<span class="text-sm italic">{{ track.artist }}</span>
					<span><mat-icon *ngIf="track.isNumberOneGuess()" class="align-middle">trophy</mat-icon></span>
				</div>
				<div class="ms-auto">
					<button class="btn btn-ghost btn-circle text-gray-400 hover:text-current" (click)="onTrackSelected(track)">
						<mat-icon>more_vert</mat-icon>
					</button>
				</div>
			</div>
		</div>
	</div>
</ng-container>
