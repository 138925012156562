import { NgModule } from '@angular/core';
import { UiModule } from '../ui/ui.module';
import { SettingsComponent } from './settings/settings.component';
import { CallbackComponent } from './callback/callback.component';
import { SpotifyAccountComponent } from './spotify-account/spotify-account.component';
import { UserChoiceReclamationComponent } from './reclaim-points/user-choice-reclamation.component';
import { SettingsRoutingModule } from './settings-routing.module';

@NgModule({
	declarations: [SettingsComponent, CallbackComponent, UserChoiceReclamationComponent, SpotifyAccountComponent],
	imports: [UiModule, SettingsRoutingModule]
})
export class SettingsModule {}
