import { Injectable } from '@angular/core';
import { catchError, filter, firstValueFrom, Observable, Subject, takeUntil, tap, throwError } from 'rxjs';
import { AuthService } from './auth.service';
import { StorageService } from './storage.service';
import { map } from 'rxjs/operators';
import { UserService } from './user.service';
import { Community } from '../../../shared/model/community';
import { SessionData } from '../model/session-data';
import { SessionState } from '../model/session-state';
import { User } from '../../../shared/model/user';
import { NoCurrentCommunityError, UserDoesNotExistError, UserNotFoundError } from '../error/errors';
import { FirebaseUser } from '../model/firebase-user';
import { Session } from '../model/session';
import { FirestoreReference } from '../../../shared/model/firestore-reference';

@Injectable({
	providedIn: 'root'
})
export class SessionService {
	private _session!: Session;
	private _loggedIn$!: Observable<boolean>;
	private _registrationPending?: boolean;

	private destroyed$ = new Subject<void>();
	constructor(private authService: AuthService, private storage: StorageService, private userService: UserService) {
		this.initialize();
	}

	getSession(): SessionData {
		return this._session.get();
	}

	getUser(): User {
		const user: User = new User(this._session.get().user);
		if (!user) {
			throw new UserNotFoundError();
		}
		return user;
	}

	getCommunity(): Community {
		const community: Community = this._session.get().user?.currentCommunity as Community;
		if (!community) {
			throw new NoCurrentCommunityError();
		}
		return community;
	}

	getCommunityReferencePath(): FirestoreReference {
		const community: Community = this.getCommunity();
		return `communities/${community.id}`;
	}

	async activateCommunity(community: Community): Promise<void> {
		const currentCommunity = await this.storage.getCommunity();
		await this.storage.setCommunity(community);
		try {
			const user: User = this.getUser();
			this._session.updateByUser({ ...user, currentCommunity: community } as User);
		} catch (e) {
			if (currentCommunity) {
				await this.storage.setCommunity(currentCommunity);
			}
			throw e;
		}
	}

	async loadAndActivateCommunity(community: Community): Promise<void> {
		return await this.activateCommunity(community);
	}

	selectUser(): Observable<User | undefined> {
		return this._session.select().pipe(
			map((session: SessionData) => {
				return session.user;
			})
		);
	}

	selectUserAndFilterNilValue(): Observable<User> {
		return this.selectUser().pipe(filter((user: User | undefined): user is User => !!user));
	}

	/**
	 * Returns a stream of the wrapper of the selectAuth stream of firebase, mapping it to a boolean
	 * indicating the Login state (versus firebase).
	 *
	 * This stream must not have a start value in order to prevent guards from reading wrong information.
	 */
	selectIsLoggedIn(): Observable<boolean> {
		return this._loggedIn$;
	}

	private initialize() {
		this._session = new Session();
		const authState$ = this.authService.selectAuthState();
		this._loggedIn$ = authState$.pipe(map((firebaseUser: FirebaseUser | null) => !!firebaseUser));
		authState$.pipe(filter(() => !this._registrationPending)).subscribe(async (firebaseUser: FirebaseUser | null) => {
			if (firebaseUser) {
				await this.updateFirebaseUserAndLoadUser(firebaseUser);
			} else {
				this._session.destroy();
			}
		});
	}

	async signUpAndStoreUser({ email, password, username }: { email: string; password: string; username: string }): Promise<User> {
		this._registrationPending = true;
		const firebaseUser: FirebaseUser = await this.authService.signup({ email, password, username });
		if (firebaseUser) {
			await this.userService.storeUser({ username, firebaseUser });
		}
		delete this._registrationPending;
		return this.updateFirebaseUserAndLoadUser(firebaseUser);
	}

	private destroySession() {
		delete this._registrationPending;
		this._session.destroy();
		this.destroyed$.next();
		this.destroyed$.complete();
	}

	private async updateFirebaseUserAndLoadUser(firebaseUser: FirebaseUser) {
		this._session.updateByFirebaseUser(firebaseUser);
		const user = await firstValueFrom(
			this.loadUser(firebaseUser.uid).pipe(
				takeUntil(this.destroyed$),
				catchError((e) => {
					if (!(e instanceof UserDoesNotExistError)) {
						throw e;
					}
					// TODO: Proper error handling
					return throwError(e);
				})
			)
		);
		this._session.updateByUser(user);
		return user;
	}

	private loadUser(uid: string): Observable<User> {
		const firebaseUser = this._session.get().firebaseUser;
		if (!firebaseUser) {
			throw new Error('FirebaseUser not found');
		}
		const firebaseUserEmail: string = firebaseUser.email as string;
		this._session.updateBySessionState(SessionState.USER_PENDING);
		return this.userService.selectOneWithMemberships(uid).pipe(
			tap((user: User) => {
				this._session.updateBySessionState(SessionState.VALID);
			})
		);
	}

	async logout() {
		await this.authService.logout();
	}
}
