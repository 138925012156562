<div>
	<mat-icon>warning</mat-icon>
	<h3 ngSwitch="errorType">
		<!--		<ng-container *ngIf="errorType === errorTypes.NotFound">Not Found</ng-container>-->
		<!--		<ng-container *ngIf="errorType === errorTypes.Unauthorized">Internal Server Error</ng-container>-->
		<!--		<ng-container *ngIf="errorType === errorTypes.Forbidden">Forbidden</ng-container>-->
		<ng-container>Es ist ein Fehler aufgetreten.</ng-container>
	</h3>

	<p>{{ message }}</p>
	<button mat-button (click)="onOk()">OK</button>
</div>
