import { Component, Inject } from '@angular/core';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';

@Component({
	selector: 'app-confirmation-dialog',
	templateUrl: './confirmation-dialog.component.html',
	styleUrls: ['./confirmation-dialog.component.css']
})
export class ConfirmationDialogComponent {
	title: string = null;
	message: string = 'Bist du dir sicher, dass du das tun willst?';
	confirmButtonText = 'Ja';
	cancelButtonText = 'Nein';
	cancelButtonVisible: boolean = true;
	imageUrl?: string;

	constructor(@Inject(DIALOG_DATA) private data: any, private dialogRef: DialogRef<ConfirmationDialogComponent>) {
		if (data) {
			this.title = data.title || this.title;
			this.message = data.message || this.message;
			this.confirmButtonText = data.confirmButtonText ?? this.confirmButtonText;
			this.cancelButtonText = data.cancelButtonText ?? this.cancelButtonText;
			this.cancelButtonVisible = data.cancelButtonVisible ?? this.cancelButtonVisible;
			this.imageUrl = data.imageUrl;
		}
	}

	onConfirmClick(): void {
		this.dialogRef.close(true as any);
	}

	onCancelClick(): void {
		this.dialogRef.close(false as any);
	}
}
