import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Round } from '../../../../../shared/model/round';
import { SessionService } from '../../../services/session.service';
import { UserChoiceService } from '../../../services/user-choice.service';
import { UserChoice } from '../../../../../shared/model/user-choice';
import { AngularFirestore } from '@angular/fire/compat/firestore';

class DashboardItem {
	round: Round;
	userChoice: UserChoice;
	active: boolean;
}
@Component({
	selector: 'app-dashboard',
	templateUrl: './dashboard.component.html',
	styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
	upcomingItems: DashboardItem[] = [];
	pastItems: DashboardItem[] = [];
	highlightedItems: DashboardItem[] = [];

	constructor(private router: Router, private sessionService: SessionService, private userChoiceService: UserChoiceService, private firestore: AngularFirestore) {}

	ngOnInit(): void {
		this.getRounds();
	}

	getRounds(): void {
		const communityRefPath = this.sessionService.getCommunityReferencePath();

		// TODO: Mit @Olewahn klären: Wie löst man das am besten auf? Initial möchte ich erst die Choices und dan die Runden. Wenn die Choices neu getriggered werden, möchte aber nicht mehr die Runden laden
		this.userChoiceService.getUserChoicesByUser({ user: this.sessionService.getUser() }).subscribe((userChoices: UserChoice[]) => {
			const items: DashboardItem[] = [];
			items.push(...userChoices.map((choice: UserChoice) => ({ round: choice.round, userChoice: choice, active: this.isRoundActive(choice.round) })));
			const existingRoundIds = userChoices.map((choice: UserChoice) => choice.roundRef?.id) ?? [];
			this.userChoiceService.getRoundsWithoutUserChoice({ existingRoundIds, communityRefPath }).subscribe((rounds: Round[]) => {
				rounds.forEach((round: Round) => {
					const item = items.find((item: DashboardItem) => item.round?.id === round.id);
					if (item) {
						item.round = round;
					} else {
						items.push({ round, userChoice: null, active: this.isRoundActive(round) });
					}
				});
				const { upcomingItems, pastItems, highlightedItems } = this.groupAndSortDashboardItems(items);
				this.upcomingItems = upcomingItems;
				this.pastItems = pastItems;
				this.highlightedItems = highlightedItems;
			});
		});
	}

	groupAndSortDashboardItems(items: DashboardItem[]): { upcomingItems: DashboardItem[]; pastItems: DashboardItem[]; highlightedItems: DashboardItem[] } {
		const upcomingItems: DashboardItem[] = [];
		const pastItems: DashboardItem[] = [];

		const now = new Date();
		items.forEach((item: DashboardItem) => {
			if (item.round.startsAt >= now) {
				upcomingItems.push(item);
			} else {
				pastItems.push(item);
			}
		});

		upcomingItems.sort((a, b) => (a.round.startsAt > b.round.startsAt ? 1 : -1));
		pastItems.sort((a, b) => (a.round.startsAt < b.round.startsAt ? 1 : -1));

		return { upcomingItems, pastItems, highlightedItems: this.getHighlightedItems({ upcomingItems, pastItems }) };
	}

	/**
	 * Sehr simple Logik, funktioniert aber erstmal.
	 * @param upcomingItems
	 * @param pastItems
	 */
	getHighlightedItems({ upcomingItems, pastItems }: { upcomingItems: DashboardItem[]; pastItems: DashboardItem[] }): DashboardItem[] {
		const highlightedItems: DashboardItem[] = [];
		if (upcomingItems.length > 0) {
			highlightedItems.push(upcomingItems[0]);
		}
		if (pastItems.length > 0) {
			highlightedItems.push(pastItems[0]);
		}
		return highlightedItems;
	}

	isRoundActive(round: Round): boolean {
		const today = new Date();
		today.setHours(0, 0, 0, 0);
		const roundDate = new Date(round.startsAt);
		roundDate.setHours(0, 0, 0, 0);
		return roundDate.getTime() === today.getTime();
	}

	getRoundTimeMessage(round: Round): string {
		const now = new Date();
		const roundDate = new Date(round.startsAt);
		const diffInTime = roundDate.getTime() - now.getTime();
		const diffInDays = Math.ceil(diffInTime / (1000 * 3600 * 24)); // diff in milliseconds to days

		if (diffInDays === 0) {
			return `Läuft heute`;
		} else if (diffInDays < 0) {
			return `Beendet vor ${Math.abs(diffInDays)} Tagen`;
		} else {
			return `Startet in ${diffInDays} Tagen`;
		}
	}

	async onItemClick(item: DashboardItem) {
		if (item.round.hasStarted()) {
			await this.viewRoundResult(item.round);
		} else {
			await this.editUserChoice(item);
		}
	}

	async editUserChoice(item: DashboardItem): Promise<void> {
		let id = item.userChoice?.id;
		if (!id) {
			id = await this.createUserChoice(item.round);
		}
		await this.router.navigate([`user-choice-edit/${id}`]);
	}

	async viewRoundResult(round: Round): Promise<void> {
		await this.router.navigate([`round-result-view/${round.id}`]);
	}

	async createUserChoice(round: Round): Promise<string> {
		const roundRef = this.firestore.collection('rounds').doc(round.id).ref;
		const user = this.sessionService.getUser();
		const userRef = this.firestore.collection('users').doc(user.id).ref;

		const vo: object = { roundRef, userRef, round: round.toFirestoreAsReference(), user: user.toFirestore() };

		const result = await this.userChoiceService.createUserChoice(vo);
		return result.id;
	}
}
