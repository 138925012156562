export class FirebaseDate {
	public seconds: number;
	public nanoseconds: number;
	static toDate(firebaseDate: FirebaseDate): Date | undefined {
		return firebaseDate.seconds ? new Date(firebaseDate.seconds * 1000) : undefined;
	}

	static fromDate(date: Date): FirebaseDate | undefined {
		return date instanceof Date ? { seconds: date.getTime() / 1000, nanoseconds: 0 } : undefined;
	}
}
