export class LodashUtil {
	public static toNumber(value: unknown): number | null {
		const number = Number(value);
		return this.isNumber(number) ? number : null;
	}

	public static isNumber(value: unknown): boolean {
		return typeof value === 'number' && isFinite(value);
	}

	public static isString(value: unknown): boolean {
		return typeof value === 'string';
	}

	public static isEmpty(value: string | object | any[] | null | undefined): boolean {
		if (Array.isArray(value) || typeof value === 'string') {
			return value.length === 0;
		} else if (typeof value === 'object') {
			// @ts-ignore
			return Object.keys(value).length === 0;
		}
		return false;
	}

	public static isNotEmpty(value: string | object | any[]): boolean {
		return !this.isEmpty(value);
	}

	public static toLowerCase(value: any): string {
		if (typeof value !== 'string') {
			return undefined;
		}
		return value?.toLowerCase();
	}
}
