import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { SessionService } from '../services/session.service';

@Injectable({
	providedIn: 'root'
})
export class AuthGuard implements CanActivate {
	constructor(private router: Router, private sessionService: SessionService) {}

	canActivate(): Observable<boolean | UrlTree> {
		return this.sessionService.selectIsLoggedIn().pipe(
			map((loggedIn: boolean) => {
				return loggedIn || this.router.parseUrl('/login');
			})
		);
	}
}
