<div class="dialog-content mat-typography">
	<!-- TODO Hier weitermachen, aktuell im Draft Mode
<h3>Spielrunden</h3>
<p>Eine Spielrunde besteht aus einem Motto, wie beispielsweise Die besten Songs der 60s oder der besten Songs über Essen.</p>
<p>Jeder Spieler der an der Runde teilnimmt erhält eine Bingo-Karte mit 25 Feldern. Die Top 100 werden in fünf Reihen zu je fünf Songs präsentiert, wobei die Reihen folgende Plätze repräsentieren: Reihe 1: Plätze 100-81 Reihe 2: Plätze 80-61 Reihe 3: Plätze 60-41 Reihe 4: Plätze 40-21 Reihe 5: Plätze 20-01 Für jeden Song der gespielt wird, erhält ein Spieler einen Punkt. Wenn der Punkt in die richtige Reihe fällt, erhält der Spieler einen weiteren Punkt. Wenn der Punkt in die richtige Spalte fällt, erhält der Spieler einen weiteren Punkt.</p>
-->
	<div *ngIf="currentStep === 1" class="hero bg-base-200">
		<div class="hero-content text-center">
			<div class="max-w-md">
				<h1 class="text-5xl font-bold">TOP 100 – Spielregeln</h1>
				<p class="py-6">Hier bekommst du eine Übersicht über die Regeln von Top100.</p>
			</div>
		</div>
	</div>

	<div *ngIf="currentStep === 2" class="hero bg-base-200">
		<div class="hero-content flex-col lg:flex-row-reverse">
			<img src="https://img.daisyui.com/images/stock/photo-1635805737707-575885ab0820.webp" class="max-w-sm rounded-lg shadow-2xl" />
			<div>
				<h1 class="text-5xl font-bold">Die Jury-Listen</h1>
				<p class="py-6">
					Willkommen beim Radio Eins Sommersonntage Musik-Quiz! Bevor wir in das Spiel eintauchen, lassen Sie uns das Wesen der Radio Eins Sommersonntage erkunden. Diese jährliche Veranstaltung ist eine Feier der Musik, die eine kuratierte Auswahl der 100 besten Songs präsentiert, die den Sommer geprägt haben. An jedem Sonntag werden die Zuhörer mit einer vielfältigen Playlist verwöhnt, die verschiedene Genres und Epochen umfasst und den Geist und die Nostalgie des Sommers einfängt. Die Radio Eins Sommersonntage sind nicht nur dazu da, Musik zu hören; sie bieten die Möglichkeit, die Geschichten und Emotionen hinter jedem Titel zu erleben. Egal, ob Sie ein langjähriger Fan sind oder neu in der Reihe, diese Sonntage bieten eine einzigartige Gelegenheit, die Songs zu entdecken oder wiederzuentdecken, die unsere Sommer geprägt haben. In diesem Quiz werden Sie Ihr Wissen über diese ikonischen Tracks testen und mehr über die Künstler und die Geschichte dahinter erfahren. Machen Sie sich
					bereit für eine musikalische Reise, die die lebendigen und zeitlosen Klänge des Sommers feiert! Falls Sie weitere Anpassungen benötigen oder zusätzliche Informationen einfügen möchten, lassen Sie es mich wissen!
				</p>
			</div>
		</div>
	</div>
	<div *ngIf="currentStep === 3" class="hero bg-base-200">
		<div class="hero-content flex-col lg:flex-row-reverse">
			<img src="https://img.daisyui.com/images/stock/photo-1635805737707-575885ab0820.webp" class="max-w-sm rounded-lg shadow-2xl" />
			<div>
				<h1 class="text-5xl font-bold">Die Jury-Listen</h1>
				<p class="py-6">Die Top100</p>
			</div>
		</div>
	</div>
</div>
<div class="flex justify-between">
	<button [disabled]="currentStep === 0" class="btn" (click)="previousStep()">Zurück</button>
	<button class="btn" (click)="nextStep()">Weiter</button>
</div>
