import { Component, Inject, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';

enum ApplicationErrorType {
	NotFound = 'not-found',
	Unauthorized = 'unauthorized',
	Forbidden = 'forbidden'
}
@Component({
	selector: 'app-error',
	templateUrl: './error.component.html',
	styleUrl: './error.component.css'
})
export class ErrorComponent {
	@Input()
	message?: string;
	@Input()
	errorType!: ApplicationErrorType;

	errorTypes = ApplicationErrorType;

	constructor(private activatedRoute: ActivatedRoute, private router: Router, @Inject(DIALOG_DATA) private data: any, private dialogRef: DialogRef<ErrorComponent>) {
		if (data) {
			this.message = data.message;
		} else {
			this.errorType = this.activatedRoute.snapshot.data['errorType'];
			this.message = this.activatedRoute.snapshot.data['message'];
		}
	}

	async onOk() {
		if (this.dialogRef) {
			this.dialogRef.close();
		} else {
			await this.router.navigate(['/']);
		}
	}
}
