import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Track } from '../../../../../shared/model/track';
import { UserChoiceService } from '../../../services/user-choice.service';
import { UserChoice } from '../../../../../shared/model/user-choice';
import { OverlayService } from '../../../services/overlay.service';

@Component({
	selector: 'app-user-choice-edit-list',
	templateUrl: './user-choice-edit-list.component.html',
	styleUrls: ['./user-choice-edit-list.component.css']
})
export class UserChoiceEditListComponent {
	@Input()
	userChoice: UserChoice;
	@Output() trackSelected = new EventEmitter<Track>();
	@Output() trackAdded = new EventEmitter<string>();

	constructor(private userChoiceService: UserChoiceService, private overlayService: OverlayService) {}

	async drop(event: CdkDragDrop<Track[], any>) {
		const newSectionId = event.container.id;
		const previousSectionId = event.previousContainer.id;
		const newSectionTracks = event.container.data;
		const previousSectionTracks = event.previousContainer.data;

		const newIndex = event.currentIndex;
		const previousIndex = event.previousIndex;

		this.overlayService.showLoadingOverlay();
		await this.updatePositionAfterDrop({ newSectionId, previousSectionId, newSectionTracks, previousSectionTracks, newIndex, previousIndex });
		this.overlayService.closeLoadingOverlay();
	}

	async updatePositionAfterDrop({ newSectionId, previousSectionId, newSectionTracks, previousSectionTracks, newIndex, previousIndex }: { newSectionId: string; previousSectionId: string; newSectionTracks: Track[]; previousSectionTracks: Track[]; newIndex: number; previousIndex: number }) {
		if (newSectionId === previousSectionId) {
			moveItemInArray(newSectionTracks, previousIndex, newIndex);
			this.userChoice.updatePosition({ sectionId: newSectionId, tracks: newSectionTracks });
		} else {
			transferArrayItem(previousSectionTracks, newSectionTracks, previousIndex, newIndex);
			this.userChoice.updatePosition({ sectionId: newSectionId, tracks: newSectionTracks });
			this.userChoice.updatePosition({ sectionId: previousSectionId, tracks: previousSectionTracks });
		}
		await this.userChoiceService.updateUserChoice(this.userChoice);
	}

	onTrackSelected(track: Track): void {
		this.trackSelected.emit(track);
	}

	onTrackAdded(sectionId: string): void {
		this.trackAdded.emit(sectionId);
	}
}
