import { NgModule } from '@angular/core';
import { CdkDrag, CdkDropList, DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';

@NgModule({
	imports: [CommonModule, CdkDrag, CdkDropList],
	declarations: [],
	exports: [CdkDrag, CdkDropList, DragDropModule]
})
export class CdkModule {}
