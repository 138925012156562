import { Component, Inject, OnInit } from '@angular/core';
import { Section } from '../../../../../shared/model/section';
import { debounceTime, Subject } from 'rxjs';
import { SpotifyService } from '../../../services/external/spotify.service';
import { SpotifySearchTrackResult } from '../../../../../shared/model/external/spotify/dto/spotify-search-track-result';
import { NgForm } from '@angular/forms';
import { PlainTrack, Track } from '../../../../../shared/model/track';
import { UserChoice } from '../../../../../shared/model/user-choice';
import { DialogRef, DIALOG_DATA } from '@angular/cdk/dialog';

@Component({
	selector: 'app-track-create',
	templateUrl: './track-create.component.html',
	styleUrls: ['./track-create.component.css']
})
export class TrackCreateComponent implements OnInit {
	search: string;
	name: string;
	artist: string;
	uri: string;
	id: string;
	sectionId: string;
	userChoice: UserChoice;
	mode: 'search' | 'edit' = 'search';
	spotifySearchResults: PlainTrack[] = null;
	isLoading: boolean = false;

	private searchSubject = new Subject<string>();

	constructor(public dialogRef: DialogRef<TrackCreateComponent>, @Inject(DIALOG_DATA) public data: { sections: Section[]; sectionId: string; currentUserTracks: Track[]; userChoice: UserChoice }, private spotifyService: SpotifyService) {
		this.userChoice = data.userChoice;
		this.sectionId = data.sectionId ?? this.userChoice.sectionsList[0]?.id;
	}

	ngOnInit() {
		this.searchSubject.pipe(debounceTime(500)).subscribe(async (search: string) => {
			this.search = search;
			await this.searchSpotify();
		});
	}

	onSearchChange(search: string): void {
		this.searchSubject.next(search);
	}

	switchMode(): void {
		this.mode = this.mode === 'search' ? 'edit' : 'search';
	}

	async searchSpotify(): Promise<void> {
		if (!this.search) {
			return;
		}

		this.isLoading = true;
		const spotifySearchTrackResult: SpotifySearchTrackResult = await this.spotifyService.searchTrack(this.search);
		this.spotifySearchResults = SpotifySearchTrackResult.convertToTrackListAndRemoveDuplicates({ spotifySearchTrackResult, currentUserTracks: this.userChoice.tracks });
		this.isLoading = false;
	}

	async submitTrack(form: NgForm): Promise<void> {
		if (form.valid) {
			const formValue = { ...form.value, spotify: { id: this.id, uri: this.uri } };
			this.dialogRef.close(formValue);
		} else {
			form.form.markAllAsTouched();
		}
	}

	onSelect(track: PlainTrack): void {
		this.name = track.name;
		this.artist = track.artist;
		this.id = track.spotify.id;
		this.uri = track.spotify.uri;
		this.switchMode();
	}
}
