import { Injectable } from '@angular/core';
import { get, put } from '../../services/http.service';
import { StorageService } from '../../services/storage.service';
import { OverlayService } from '../../services/overlay.service';
import { Track } from '../../../../shared/model/track';

@Injectable({
	providedIn: 'root'
})
export class SpotifyPlayerService {
	private readonly SPOTIFY_API_URL = 'https://api.spotify.com/v1';

	constructor(private storageService: StorageService, private overlayService: OverlayService) {}

	async getMyProfile() {
		const accessToken = await this.storageService.getSpotifyAccessToken();
		const headers = { Authorization: `Bearer ${accessToken}` };
		return get({ url: `${this.SPOTIFY_API_URL}/me`, config: { headers } });
	}

	async playTrack(track: Track) {
		if (track?.spotify?.uri) {
			await this.playTrackByUri({ uri: track.spotify.uri });
		} else {
			this.overlayService.error({ message: 'Es konnten keine Spotify-Daten für diesen Song gefunden. Das liegt vermutlich daran, dass der Song manuell hinzugefügt wurde.' });
		}
	}

	private async playTrackByUri({ uri }: { uri: string }) {
		const accessToken = await this.storageService.getSpotifyAccessToken();
		const headers = { Authorization: `Bearer ${accessToken}` };

		// Get the user's available devices
		const devices: any = await get({ url: `${this.SPOTIFY_API_URL}/me/player/devices`, config: { headers } });

		if (devices && devices.devices && devices.devices.length > 0) {
			// Choose a device (the first one in the list)
			const deviceId = devices.devices[0].id;

			// Play a random song on the chosen device
			const trackUris = [uri];
			await put({ url: `${this.SPOTIFY_API_URL}/me/player/play?device_id=${deviceId}`, data: { uris: trackUris }, config: { headers } });
		} else {
			this.overlayService.error({ message: 'Spotify ist auf keinem der Geräte aktiv. Bitte starte Spotify auf einem Gerät und versuche es erneut.' });
		}
	}
}
