<app-round-summary [round]="round" *ngIf="round"></app-round-summary>

<div class="px-safe">
	<div class="p-3">
		<div class="join border center">
			<button class="btn btn-ghost join-item" [ngClass]="{ '!text-primary !bg-gray-100': mode === 'results' }" (click)="switchMode('results')">
				<mat-icon>format_list_numbered</mat-icon>
			</button>
			<button class="btn btn-ghost join-item" [ngClass]="{ '!text-primary !bg-gray-100': mode === 'choices' }" (click)="switchMode('choices')">
				<mat-icon>grid_on</mat-icon>
			</button>
		</div>
	</div>
</div>

<div *ngIf="round && mode === 'results'" class="px-safe">
	<app-round-result-view [round]="round"></app-round-result-view>
</div>

<div *ngIf="(!choices || choices.length < 1) && mode === 'choices'" class="px-safe">
	<div class="p-3">
		<div class="text-center text-gray-500">Für diese Runde liegen noch keine Bingos vor.</div>
	</div>
</div>
<div *ngIf="choices && mode === 'choices'" class="px-safe">
	<div class="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-3 p-3">
		<button *ngFor="let choice of choices; let i = index" (click)="showChoice(choice.id)" class="text-left bg-gray-100 hover:bg-gray-200 rounded-md p-3">
			<div class="flex">
				<div class="grow font-bold overflow-hidden text-ellipsis pb-3">
					<span class="font-bold min-w-8">{{ choice.getResultPosition(choices) }}.</span> {{ choice?.user?.username }}
				</div>
				<div>
					<div class="badge badge-xs">{{ choice.score }}</div>
				</div>
			</div>

			<div class="grid grid-cols-5 gap-1">
				<div
					*ngFor="let track of choice.getSortedTracks()"
					class="aspect-square bg-white rounded-md flex items-center justify-center"
					[ngClass]="{
						'track-matches-result-list': track.matchesResult(),
						'track-matches-section': track.matchesSection(),
						'track-is-placeholder': track.isPlaceholder()
					}"
				>
					<mat-icon *ngIf="track.matchesNumberOne()" class="!size-4 !leading-none !text-base">trophy</mat-icon>
					<mat-icon *ngIf="track.artist === 'Joker' && track.name === '⭐'" class="!size-4 !leading-none !text-base">star</mat-icon>
				</div>
			</div>
		</button>
	</div>
</div>

<ng-template #detailDialog>
	<app-dialog-header [title]="selectedChoice?.getUserName()"></app-dialog-header>

	<div class="dialog-content">
		<div class="flex flex-col divide-y">
			<div *ngFor="let section of selectedChoice.getSectionsWithoutPendingPicks()">
				<div class="bg-gray-200 p-3 text-center text-sm font-bold">{{ section.name }}</div>
				<ng-container *ngFor="let track of selectedChoice.getTracksInScoringRangeBySection(section.id)">
					<div
						class="border-b px-0.5 py-0.5 flex gap-3 items-center"
						[ngClass]="{
							'track-matches-result-list': track.matchesResult(),
							'track-matches-section': track.matchesSection()
						}"
					>
						<div *ngIf="track.isPlaceholder()">–</div>
						<div *ngIf="!track.isPlaceholder()">
							<span class="font-bold min-w-8">{{ track.getAbsolutPosition() }}.</span>
							{{ track.name }} <span> – </span> <span class="italic text-sm">{{ track.artist }}</span>
							<span><mat-icon *ngIf="track.matchesNumberOne()" class="align-middle">trophy</mat-icon></span>
							<span *ngIf="track.getMatchingTrackPosition(round)">
								<span class="badge ml-1">{{ track.getMatchingTrackPosition(round) }}.</span>
							</span>
						</div>

						<div class="ms-auto">
							<button class="btn btn-ghost btn-circle text-gray-400 hover:text-current" (click)="onReclaimPointsForTrack(track)">
								<mat-icon>support_agent</mat-icon>
							</button>
						</div>
					</div>
				</ng-container>
			</div>
		</div>
	</div>
</ng-template>

<ng-template #reclaimTrack>
	<app-dialog-header [title]="selectedChoice?.getUserName()"></app-dialog-header>
	<div class="dialog-content">
		<span>Du möchtest Punkte für den Song {{ reclaimPointsForTrack.name }} ({{ reclaimPointsForTrack.artist }}) reklamieren? Dann wähle bitte einen Song aus der Liste aus.</span>
		<div class="flex flex-col divide-y">
			<button class="w-full text-left p-3 hover:bg-gray-200" *ngFor="let result of round.results; let last = last" (click)="onReclaimPointsForTrackSelect(result)">
				<span class="font-bold">{{ result.position }}. </span><span class="italic">{{ result.track }}</span> <span class="text-gray-500"> - </span> <span class="text-sm italic">{{ result.artist }}</span>
			</button>
		</div>
	</div>
</ng-template>
