<div class="form-control">
	<div class="relative">
		<button class="select select-bordered w-full flex justify-between items-center" (click)="toggleDropdown()" type="button">
			<span>{{ getSelectedSectionName() || 'Select Section' }}</span>
		</button>

		<ul *ngIf="dropdownOpen" class="fixed z-10 bg-white border border-gray-300 rounded-md shadow-lg" [ngStyle]="dropdownStyles">
			<li *ngFor="let section of userChoice.sectionsList" class="cursor-pointer px-4 py-2 hover:bg-gray-200" (click)="selectSection(section.id)">
				<span [class.text-red-600]="userChoice.getSectionLength(section.id) >= 5">
					{{ section.name }}
				</span>
				<ng-container *ngIf="!section.outOfScoreRange">
					<span class="text-xs ml-2"> ({{ userChoice.getSectionLength(section.id) }} von 5) </span>
					<span *ngIf="userChoice.maxSectionLengthExceeded(section.id)" class="ml-2 badge badge-error"> Voll </span>
				</ng-container>
			</li>
		</ul>
	</div>
	<div class="form-field-warn-message" *ngIf="userChoice.maxSectionLengthExceeded(sectionId)">Es sind bereits 5 Songs in dieser Kategorie. Der Song kann hinzugefügt werden, geht aber nicht in die Wertung ein.</div>
	<div class="form-field-warn-message" *ngIf="getCurrentSection()?.outOfScoreRange">Songs in dieser Kategorie gehen nicht in die Wertung ein.</div>
</div>
