import { Injectable } from '@angular/core';
import { Action, AngularFirestore, DocumentSnapshot } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';
import { FirebaseUser } from '../model/firebase-user';

@Injectable({
	providedIn: 'root'
})
export class FirestoreService {
	constructor(private firestore: AngularFirestore) {}

	selectOne<T>(path: string, docId: string): Observable<Action<DocumentSnapshot<T>>> {
		return this.firestore.collection<T>(path).doc(docId).snapshotChanges();
	}

	getData<T>(snapshot: Action<DocumentSnapshot<T>>): T {
		if (!snapshot.payload.exists) {
			throw new Error('Entity does not exist');
		}
		return snapshot.payload.data();
	}

	async storeUser({ username, firebaseUser }: { username: string; firebaseUser: FirebaseUser }): Promise<void> {
		const collection = this.firestore.collection(`users`);
		await collection.doc(firebaseUser.uid).set({
			username: username,
			uid: firebaseUser.uid
		});
	}
}
