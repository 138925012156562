export class SpotifyAuthorize {
	SPOTIFY_AUTHORIZE_URL = 'https://accounts.spotify.com/authorize';
	CLIENT_ID = '944b590644414b06928f515ba345e14e';
	SCOPES = [
		//Listening History
		'user-read-recently-played',
		'user-top-read',
		'user-read-playback-position',
		//Spotify Connect
		'user-read-playback-state',
		'user-modify-playback-state',
		'user-read-currently-playing',
		//Playback - For SDK Playback //https://developer.spotify.com/documentation/web-playback-sdk/quick-start/
		'streaming',
		//Playlists
		'playlist-modify-public',
		'playlist-modify-private',
		'playlist-read-private',
		'playlist-read-collaborative',
		//Library
		'user-library-modify',
		'user-library-read',
		//Users - For SDK Playback //https://developer.spotify.com/documentation/web-playback-sdk/quick-start/
		'user-read-email',
		'user-read-private'
	];

	createAuthorizeURL() {
		console.log(window.location.origin);
		const params = new URLSearchParams({
			client_id: this.CLIENT_ID,
			redirect_uri: `${window.location.origin}/callback`,
			// redirect_uri: `https://top100.bingo/callback`,
            // redirect_uri: `http://localhost:4200/callback`,
			scope: this.SCOPES.join(' '),
			response_type: 'token'
		});
		return `${this.SPOTIFY_AUTHORIZE_URL}?${params.toString()}`;
	}
}
