import axios, { AxiosRequestConfig } from 'axios';

export const get = async ({ url, config }: { url: string; config?: AxiosRequestConfig }): Promise<any> => {
	return axios
		.get(url, config)
		.then((result) => {
			return result.data;
		})
		.catch((error) => {
			logError({ error, message: 'Error in http.service#get' });
			throw error;
		});
};

export const post = async ({ url, data, config }: { url: string; data?: any; config?: AxiosRequestConfig }): Promise<any> => {
	return axios
		.post(url, data, config)
		.then((result) => {
			return result.data;
		})
		.catch((error) => {
			logError({ error, message: 'Error in http.service#post' });
			throw error;
		});
};

export const patch = async ({ url, data, config }: { url: string; data: any; config?: AxiosRequestConfig }): Promise<any> => {
	return axios
		.patch(url, data, config)
		.then((result) => {
			return result.data;
		})
		.catch((error) => {
			logError({ error, message: 'Error in http.service#patch' });
			throw error;
		});
};

export const put = async ({ url, data, config }: { url: string; data: any; config?: AxiosRequestConfig }): Promise<any> => {
	return axios
		.put(url, data, config)
		.then((result) => {
			return result.data;
		})
		.catch((error) => {
			logError({ error, message: 'Error in http.service#put' });
			throw error;
		});
};

export const del = async ({ url, config }: { url: string; config?: AxiosRequestConfig }): Promise<any> => {
	return axios
		.delete(url, config)
		.then((result) => {
			return result.data;
		})
		.catch((error) => {
			logError({ error, message: 'Error in http.service#del' });
			throw error;
		});
};

const logError = ({ error, message }: { error: any; message: string }) => {
	console.error(message);
	console.error(error);
};
