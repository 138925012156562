import { Component, TemplateRef, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AuthService } from '../../../services/auth.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { OverlayService } from '../../../services/overlay.service';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.css']
})
export class LoginComponent {
	email: string;
	password: string;
	emailReset: string;
	@ViewChild('form') form: NgForm;
	@ViewChild('resetDialog') resetDialog!: TemplateRef<any>;

	constructor(private authService: AuthService, private router: Router, private overlayService: OverlayService) {}

	async onSubmit(form: NgForm) {
		try {
			if (form.valid) {
				this.overlayService.showLoadingOverlay();
				const result = await this.authService.signInWithEmailAndPassword(this.email, this.password);
				this.overlayService.closeLoadingOverlay();
				if (result) {
					await this.router.navigate(['/']);
				}
			}
		} catch (e: any) {
			this.overlayService.snack({ message: `Anmeldung hat nicht geklappt: ${e.message}` });
		} finally {
			this.overlayService.closeLoadingOverlay();
		}
	}

	onResetPassword() {
		this.overlayService.open(this.resetDialog);
	}

	async onSubmitReset() {
		try {
			await this.authService.sendPasswordResetEmail(this.emailReset);
			this.overlayService.closeAll();
			this.overlayService.snack({ message: `E-Mail zum zurücksetzen des Kennworts an ${this.emailReset} geschickt.` });
		} catch (e: any) {
			if (e.code === 'auth/user-not-found') {
				this.overlayService.snack({ message: `Ich kenne keinen Nutzer mit der E-Mail-Adresse ${this.emailReset}.` });
			} else {
				this.overlayService.snack({ message: `Ein unerwarteter Fehler trat auf. Bitte versuche es nochmal.` });
			}
		}
	}
}
