import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
	declarations: [],
	imports: [CommonModule, FormsModule, ReactiveFormsModule],
	exports: [FormsModule]
})
export class FormControlModule {}
