import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { SessionService } from '../services/session.service';

@Injectable({
	providedIn: 'root'
})
export class RedirectIfLoggedInGuard {
	constructor(private router: Router, private sessionService: SessionService) {}

	canActivate(): Observable<boolean | UrlTree> {
		// FIXME: Needs session service
		return new Observable<boolean | UrlTree>((observer) => {
			observer.next(true);
			observer.complete();
		});
		// return this.sessionService.selectIsLoggedIn().pipe(
		// 	map((loggedIn: boolean) => {
		// 		return loggedIn ? this.router.parseUrl('') : true;
		// 	})
		// );
	}
}
