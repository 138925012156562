import { Component, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import party from 'party-js';
import { SessionService } from '../../../services/session.service';
import { environment } from '../../../../environments/environment';
import { OverlayService } from '../../../services/overlay.service';

@Component({
	selector: 'app-register',
	templateUrl: './register.component.html',
	styleUrls: ['./register.component.css']
})
export class RegisterComponent {
	username: string;
	email: string;
	password: string;
	@ViewChild('form') form: NgForm;
	useEmulators = environment.useEmulators;

	constructor(private sessionService: SessionService, private router: Router, private overlayService: OverlayService) {}

	async onSubmit(form: NgForm) {
		if (form.valid) {
			this.overlayService.showLoadingOverlay();
			try {
				const result = await this.sessionService.signUpAndStoreUser({ email: this.email, password: this.password, username: this.username });
				this.overlayService.closeLoadingOverlay();
				if (result) {
					this.router.navigate(['/']).then(() => {
						this.overlayService.confirm({
							message: `Herzlich Willkommen, ${this.username}! Jetzt brauchst du nur noch eine passende Community und schon kann es losgehen.`,
							confirmButtonText: "Auf geht's!",
							cancelButtonVisible: false
						});

						party.confetti(document.body, {
							count: party.variation.range(100, 200)
						});
					});
				}
			} catch (e: any) {
				this.overlayService.snack({ message: `Registration failed: ${e.message}` });
			} finally {
				this.overlayService.closeLoadingOverlay();
			}
		}
	}
}
