import { Component, Input } from '@angular/core';
import { Round } from '../../../../../shared/model/round';

@Component({
	selector: 'app-round-summary',
	templateUrl: './round-summary.component.html',
	styleUrls: ['./round-summary.component.css']
})
export class RoundSummaryComponent {
	@Input()
	round: Round;
}
