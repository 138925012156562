import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { SettingsComponent } from './settings/settings.component';
import { AuthGuard } from '../../guards/auth.guard';
import { HasCurrentCommunityGuard } from '../../guards/has-current-community.guard';
import { UserChoiceReclamationComponent } from './reclaim-points/user-choice-reclamation.component';
import { CallbackComponent } from './callback/callback.component';
import { SpotifyAccountComponent } from './spotify-account/spotify-account.component';

const routes: Routes = [
	{
		path: 'settings',
		component: SettingsComponent,
		canActivate: [AuthGuard, HasCurrentCommunityGuard]
	},
	{
		path: 'callback',
		component: CallbackComponent,
		canActivate: [AuthGuard, HasCurrentCommunityGuard]
	},
	{
		path: 'spotify-account',
		component: SpotifyAccountComponent,
		canActivate: [AuthGuard, HasCurrentCommunityGuard]
	},
	{
		path: 'reclaims',
		component: UserChoiceReclamationComponent,
		canActivate: [AuthGuard, HasCurrentCommunityGuard]
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class SettingsRoutingModule {}
