import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HasCurrentCommunityGuard } from '../../guards/has-current-community.guard';
import { AuthGuard } from '../../guards/auth.guard';

const routes = [
	{
		path: '',
		component: DashboardComponent,
		canActivate: [AuthGuard, HasCurrentCommunityGuard]
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class DashboardRoutingModule {}
