import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageService } from '../../../services/storage.service';

/**
 * FIXME(spotify): Token expires after 1 hour, refresh routine needs to be implemented.
 */
@Component({
	selector: 'app-callback',
	templateUrl: './callback.component.html',
	styleUrls: ['./callback.component.css']
})
export class CallbackComponent implements OnInit {
	accessToken: string;
	constructor(private route: ActivatedRoute, private router: Router, private storageService: StorageService) {}

	ngOnInit(): void {
		this.route.fragment.subscribe(async (fragment: string) => {
			const params = new URLSearchParams(fragment);
			const accessToken = params.get('access_token');
			if (accessToken) {
				await this.storageService.setSpotifyAccessToken(accessToken);
			}
		});
	}

	async navigateToDashboard() {
		await this.router.navigate(['/']);
	}
}
