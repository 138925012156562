export const getData = (data: any): object => {
	const values = {};
	Object.keys(data).forEach((key: string) => {
		if (data[key] !== null && data[key] !== undefined) {
			// @ts-ignore
			values[key] = data[key];
		}
	});
	return values;
};
