<app-dialog-header [title]="title"></app-dialog-header>
<div class="dialog-content">
	<p class="text-center" [innerHTML]="message"></p>
</div>
<div class="dialog-actions">
	<div class="flex gap-3 m-auto">
		<button tabindex="-1" *ngIf="cancelButtonVisible" (click)="onCancelClick()">{{ cancelButtonText }}</button>
		<button cdkFocusInitial class="btn btn-primary" (click)="onConfirmClick()" tabindex="1" data-test="confirmation_button_confirm">{{ confirmButtonText }}</button>
	</div>
</div>

<img *ngIf="imageUrl" [src]="imageUrl" alt="Confirmation image" />
