import { Component, ElementRef, EventEmitter, forwardRef, HostListener, Input, Output, ViewChild } from '@angular/core';
import { UserChoice } from '../../../../../shared/model/user-choice';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
@Component({
	selector: 'app-section-selector',
	templateUrl: './section-selector.component.html',
	styleUrls: ['./section-selector.component.css'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => SectionSelectorComponent),
			multi: true
		}
	]
})
export class SectionSelectorComponent implements ControlValueAccessor {
	@Input() userChoice: UserChoice;
	@Input() sectionId: string | null = null;
	@Output() sectionIdChange = new EventEmitter<string>();

	dropdownOpen = false;
	dropdownStyles: any = {};

	@ViewChild('dropdownButton', { static: true }) dropdownButton: ElementRef;

	private onChange: (value: string) => void;
	private onTouched: () => void;

	writeValue(value: string): void {
		this.sectionId = value;
	}

	registerOnChange(fn: (value: string) => void): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: () => void): void {
		this.onTouched = fn;
	}

	toggleDropdown() {
		this.dropdownOpen = !this.dropdownOpen;
	}

	selectSection(id: string) {
		this.sectionId = id;
		this.sectionIdChange.emit(this.sectionId);
		this.onChange(this.sectionId);
		this.dropdownOpen = false;
	}

	getSelectedSectionName(): string | undefined {
		const selectedSection = this.userChoice.sectionsList.find((section: any) => section.id === this.sectionId);
		return selectedSection ? selectedSection.name : undefined;
	}

	getCurrentSection() {
		return this.userChoice.sectionsList.find((section: any) => section.id === this.sectionId);
	}
}
