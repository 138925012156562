import { Injectable } from '@angular/core';
import { AngularFirestore, DocumentReference } from '@angular/fire/compat/firestore';
import { Community } from '../../../../../shared/model/community';
import { map } from 'rxjs/operators';
import { firstValueFrom, Observable } from 'rxjs';
import { Membership, MembershipRole } from '../../../../../shared/model/membership';
import { User } from '../../../../../shared/model/user';
import { classToObject } from '../../../../../shared/util/firebase.util';

@Injectable({
	providedIn: 'root'
})
export class MembershipService {
	constructor(protected firestore: AngularFirestore) {}

	async joinPublicCommunity({ community, user }: { community: Community; user: User }): Promise<DocumentReference> {
		const communityRef: DocumentReference = this.firestore.collection('communities').doc(community.id).ref;
		const userRef: DocumentReference = this.firestore.collection('users').doc(user.id).ref;
		const role = MembershipRole.MEMBER;
		if (await this.membershipAlreadyExists({ userRef, communityRef })) {
			throw new Error('User is already a member of this community');
		}
		return await this.firestore.collection('memberships').add({ community: classToObject(community), communityRef, user: classToObject(user), userRef, role });
	}

	createAdminMembership({ community, user }: { community: Community; user: User }): Promise<DocumentReference> {
		const communityRef: DocumentReference = this.firestore.collection('communities').doc(community.id).ref;
		const userRef: DocumentReference = this.firestore.collection('users').doc(user.id).ref;
		const role = MembershipRole.ADMIN;
		return this.firestore.collection('memberships').add({ community: classToObject(community), communityRef, user: classToObject(user), userRef, role });
	}

	public getMembershipsByUser(userId: string): Observable<Membership[]> {
		const userRef = this.firestore.collection('users').doc(userId);
		const collection = this.firestore.collection('memberships', (ref) => ref.where('userRef', '==', userRef.ref));
		return collection.snapshotChanges().pipe(
			map((changes: any) =>
				changes.map((c: any) => {
					return new Membership({ id: c.payload.doc.id, ...c.payload.doc.data() });
				})
			)
		);
	}

	private getMembershipByUserAndCommunity({ userRef, communityRef }: { userRef: DocumentReference; communityRef: DocumentReference }): Observable<Membership> {
		const collection = this.firestore.collection('memberships', (ref) => ref.where('userRef', '==', userRef).where('communityRef', '==', communityRef));
		return collection.snapshotChanges().pipe(
			map((changes: any) => {
				const first = changes[0];
				return first ? new Membership({ id: first.payload.doc.id, ...first.payload.doc.data() }) : null;
			})
		);
	}

	private async membershipAlreadyExists({ userRef, communityRef }: { userRef: DocumentReference; communityRef: DocumentReference }): Promise<boolean> {
		const membership = await firstValueFrom(this.getMembershipByUserAndCommunity({ userRef, communityRef }));
		return !!membership;
	}
}
