<div *ngIf="round" class="hero" [ngStyle]="round?.imageUrl && { 'background-image': 'url(' + round?.imageUrl + ')' }">
	<div class="hero-overlay bg-opacity-80"></div>
	<div class="hero-content text-white text-center aspect-video">
		<div>
			<div class="text-sm opacity-70">{{ round.startsAt | date : 'dd.MM.YYYY' }}</div>
			<h1 class="text-4xl">{{ round.name }}</h1>
			<p>{{ round.description }}</p>
		</div>
	</div>
</div>
