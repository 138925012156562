import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { SpotifySearchTrackResult } from '../../../../shared/model/external/spotify/dto/spotify-search-track-result';
import { searchTrack } from './spotify.service-backend-copy';
@Injectable({
	providedIn: 'root'
})
export class SpotifyService {
	constructor(private functions: AngularFireFunctions) {}

	async searchTrack(searchQuery: string): Promise<SpotifySearchTrackResult> {
		// const callable = this.functions.httpsCallable('searchTrack');
		// return callable({ searchQuery }).toPromise();
	  	// FIXME: Warum funktioniert der Zugriff über functions nicht mehr?
		return searchTrack({ searchQuery });
	}
}
