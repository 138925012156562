import { Community } from './community';
import { Membership } from './membership';
import * as firebaseUtil from '../util/firebase.util';

export class FirestoreUser {
	username: string;

	constructor(vo: any) {
		this.username = vo.username;
	}
}

export class User extends FirestoreUser {
	id: string;
	currentCommunity?: Community;
	memberships?: Membership[];
	public static readonly SPOTIFY_USER_IDS = ['MiwUWP8BgDN8UFEZCaqMMAAIpiD2' /*Josy*/, 'CDS8P6RHTNVsOKCFPQpNxuz0PEu2' /*Sascha*/];

	constructor(vo: any) {
		super(vo);
		this.id = vo.id;
		this.currentCommunity = vo.currentCommunity;
		this.memberships = vo.memberships;
	}

	setMemberships(memberships: Membership[]) {
		this.memberships = memberships;
	}

	setCurrentCommunity(community: Community) {
		this.currentCommunity = community;
	}

	toFirestore(): object {
		const data = firebaseUtil.classToObject(this);
		delete data.memberships;
		delete data.currentCommunity;
		return data;
	}

	canUseSpotify(): boolean {
		return User.SPOTIFY_USER_IDS.includes(this.id);
	}

	/**
	 * FIXME: This needs to be implemented
	 */
	isAdmin(): boolean {
		return User.SPOTIFY_USER_IDS.includes(this.id);
	}
}

export interface UserVO extends Partial<User> {}
