import { RoundResult } from './round-result';
import { FirebaseDate } from './firebase-date';
import { FirestoreReference } from './firestore-reference';
import { LodashUtil } from '../util/lodash.util';
import { classToObject, generateFirebaseUID } from '../util/firebase.util';

class FirestoreRound {
	name: string;
	description: string;
	imageUrl?: string;
	communityRef: FirestoreReference;
	resultUrl?: string;
	liveUrls?: string[];
	results: RoundResult[];
	startsAt: Date;
}

export class Round extends FirestoreRound {
	id: string;

	constructor(vo: FirestoreRound | any) {
		super();
		this.id = vo.id;
		this.name = vo.name;
		this.description = vo.description;
		this.imageUrl = vo.imageUrl;
		this.communityRef = vo.communityRef;
		this.setStartsAt(vo.startsAt);
		this.resultUrl = vo.resultUrl;
		this.liveUrls = vo.liveUrls;
		this.results = vo.results ?? [];
	}

	setStartsAt(date: Date | FirebaseDate): void {
		if (!date) {
			return;
		} else if (date instanceof Date) {
			this.startsAt = date;
		} else {
			this.startsAt = FirebaseDate.toDate(date) as Date;
		}
	}

	/**
	 * TODO: Verwendung prüfen und sauber implementieren. Muss zusätzlich über DB gelöst werden. Löschen wird aktuell ebenfalls nicht beachtet.
	 */
	hasStarted(): boolean {
		return this.startsAt < new Date();
	}

	addResult(result: RoundResult): void {
		if (LodashUtil.isEmpty(this.results)) {
			this.results = [];
		}
		result.id = result.id ?? generateFirebaseUID();
		this.results.push(result);
	}

	toFirestore(): object {
		const data: any = classToObject(this);
		data.results = this.results.map((result: RoundResult) => (result.toFirestore ? result.toFirestore() : result));
		return data;
	}

	toFirestoreAsReference(): object {
		const data: any = classToObject(this);
		delete data.results;
		return data;
	}

	getNumberOne(): RoundResult {
		return this.results?.find((result: RoundResult) => result.position === 1);
	}

	hasFinished(): boolean {
		return !!this.getNumberOne();
	}
}

export interface RoundVO extends Partial<Round> {}
