<div class="container mx-auto p-4">
	<div class="overflow-x-auto w-full">
		<table class="table w-full">
			<thead>
				<tr>
					<th class="text-left">User</th>
					<th class="text-center">Teilnahmen</th>
					<th class="text-center">Score</th>
					<th class="text-center">Score Ø</th>
					<th class="text-center">
						<div class="flex justify-center items-center">
							<svg width="35px" height="35px" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" xml:space="preserve" fill="#000000">
								<circle cx="50" cy="50" r="48" stroke="#FFD700" stroke-width="4" fill="#FFD700" />
								<circle cx="50" cy="50" r="42" stroke="white" stroke-width="3" fill="none" />
							</svg>
						</div>
					</th>
					<th class="text-center">
						<div class="flex justify-center items-center">
							<svg width="35px" height="35px" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" xml:space="preserve" fill="#000000">
								<circle cx="50" cy="50" r="48" stroke="#C0C0C0" stroke-width="4" fill="#C0C0C0" />
								<circle cx="50" cy="50" r="42" stroke="white" stroke-width="3" fill="none" />
							</svg>
						</div>
					</th>
					<th class="text-center">
						<div class="flex justify-center items-center">
							<svg width="35px" height="35px" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" xml:space="preserve" fill="#000000">
								<circle cx="50" cy="50" r="48" stroke="#cd7f32" stroke-width="4" fill="#cd7f32" />
								<circle cx="50" cy="50" r="42" stroke="white" stroke-width="3" fill="none" />
							</svg>
						</div>
					</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let user of userSummaries">
					<td class="text-left">
						<div class="flex items-center space-x-3">
							<div>
								<div class="font-bold">{{ user.username }}</div>
							</div>
						</div>
					</td>
					<td class="text-center">{{ user.gamesPlayed }}</td>
					<td class="text-center">{{ user.overallScore }}</td>
					<td class="text-center">{{ user.getAverageScore() }}</td>
					<td class="text-center">
						{{ user.numberOnePlaces }}
					</td>
					<td class="text-center">
						{{ user.numberTwoPlaces }}
					</td>
					<td class="text-center">
						{{ user.numberThreePlaces }}
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</div>
