<div class="px-safe">
	<div *ngIf="highlightedItems?.length > 0">
		<div class="divider">Highlights</div>
		<div class="grid grid-cols-2 gap-3 p-3 items-start">
			<button class="rounded-md h-full flex items-center bg-gray-100 hover:bg-gray-200 overflow-clip text-left p-2" *ngFor="let item of highlightedItems" (click)="onItemClick(item)">
				<img *ngIf="item.round?.imageUrl" src="{{ item.round?.imageUrl }}" alt="{{ item.round.name }}" class="h-16 w-16 object-cover" [ngClass]="{ 'opacity-50': item.round.hasStarted() }" />
				<div class="ml-3" [ngClass]="{ 'text-gray-500': item.round.hasStarted() }">
					<div class="font-bold text-sm">{{ item.round.name }}</div>
					<div class="text-xs text-gray-500">{{ getRoundTimeMessage(item.round) }}</div>
				</div>
			</button>
		</div>
	</div>

	<div *ngIf="upcomingItems?.length > 0">
		<div class="divider">Anstehende Spielrunden</div>
		<div class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3 p-3 items-start">
			<button class="rounded-md h-full flex flex-col justify-items-start bg-gray-100 hover:bg-gray-200 overflow-clip text-left" *ngFor="let item of upcomingItems; let i = index" (click)="onItemClick(item)">
				<img *ngIf="item.round?.imageUrl" src="{{ item.round?.imageUrl }}" alt="{{ item.round.name }}" />
				<div class="p-3">
					<div class="text-sm text-gray-500">{{ item.round.startsAt | date : 'dd.MM.YYYY' }}</div>
					<div class="font-bold">{{ item.round.name }}</div>
					<div>{{ item.round.description }}</div>
				</div>
			</button>
		</div>
	</div>

	<div *ngIf="pastItems?.length > 0">
		<div class="divider">Abgelaufene Spielrunden</div>
		<div class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3 p-3 items-start">
			<button class="rounded-md h-full flex flex-col justify-items-start bg-gray-100 hover:bg-gray-200 overflow-clip text-left" *ngFor="let item of pastItems; let i = index" (click)="onItemClick(item)">
				<img class="opacity-50" *ngIf="item.round?.imageUrl" src="{{ item.round?.imageUrl }}" alt="{{ item.round.name }}" />
				<div class="p-3 text-gray-500">
					<div class="text-sm text-slate-500">{{ item.round.startsAt | date : 'dd.MM.YYYY' }}</div>
					<div class="text-xl font-bold">{{ item.round.name }}</div>
					<div>{{ item.round.description }}</div>
				</div>
			</button>
		</div>
	</div>
</div>
